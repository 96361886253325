import React, { useState } from 'react';
import logoDLH from '../../assets/logoDLH.png'
import { DataTeam } from '../../dummy/DataTeam';
import { render } from '@testing-library/react';
import IFrame from '../../components/public/IFrame';

interface member {
    [key: string]: any
}

const About: React.FC = () => {
    const [isHovered, setIsHovered] = useState(false)
    const [member, setMember] = useState<member>()
    const setHover = (key: member) => {
        setIsHovered(true)
        setMember(key)
    }

    const renderTeam = () => {
        return <>
            <div
                className="lg:text-left w-full h-full bg-white border-2 border-basicGreen bg-opacity-75 flex flex-col justify-center rounded-lg shadow-lg px-4 py-2 transition-opacity duration-300 ease-in-out"
            >
                {member ?
                    <>
                        <div className='mb-2 flex flex-col lg:flex-row gap-4 items-center justify between w-full'>
                            <img
                                alt={member.name}
                                src={(member.profile as any)[member.nickname] || ""}
                                className='w-48 rounded-lg'
                            />
                            <div className='font-semibold text-lg text-left flex flex-col gap-2'>
                                {member.quotes}
                                <div>
                                <p className="text-black text-sm font-semibold opacity-100">{member.name}</p>
                                <p className="text-black text-xs opacity-100">
                                    <span>{member.id}</span>
                                    <span> - {member.study}</span>
                                </p>
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }
            </div>
        </>
    }

    return <>
        <div className='flex flex-col lg:flex-row gap-16 justify-between px-12 lg:px-24 py-24 items-center lg:h-screen'>
            <div className='flex flex-col lg:w-1/2'>
                <div className='flex flex-row gap-2 font-bold text-2xl lg:text-5xl justify-center lg:justify-start'>
                    INTRODUCE
                    <p className='text-orange'>A-03</p>
                </div>
                <div className='lg:text-left font-bold text-sm'>
                    <p>INTERNET OF THINGS AND DECISION SUPORT SYSTEM </p>
                    <div className='flex flex-col lg:flex-row lg:gap-2'><p>FOR </p><p className='text-basicGreen'>SMART WASTE COLLECTION SYSTEM</p></div>
                </div>
                <div className='mt-6 lg:text-justify text-sm lg:text-md'>
                    <p className='font-bold text-md'>Kota Yogyakarta krisis sampah?</p>
                    Menanggapi Permasalahan Sampah Berserak di Kota Yogyakarta: Muncullah ide untuk mengimplementasikan Sistem Pelaporan, Pemetaan, dan Pengoptimalan Rute Pengambilan Sampah.<span className='font-bold text-basicGreen text-lg'> B-COP</span> Mengatasi keterbatasan informasi penumpukan sampah di tiap TPS dan deteksi lokasi sampah berserak serta kondisi TPS. Melalui solusi Sistem Terintegrasi untuk Pelaporan, Pemetaan, dan Pengoptimalan Rute, meningkatkan Efisiensi Pengelolaan Sampah di Kota Yogyakarta
                </div>
                <div className="mt-6">
                <IFrame videoId="N6nvIIoysA8" title="Video Demonstrasi" />
                </div>
                {/* <div className='mt-6 lg:text-left text-xs'>
                    OUR MITRA :
                    <div className='mt-2 flex flex-col lg:flex-row gap-2 items-center'>
                        <img
                            alt='dlh'
                            src={logoDLH}
                            className='w-8'
                        />
                        DINAS LINGKUNGAN HIDUP
                        KOTA YOGYAKARTA
                    </div>
                </div> */}
            </div>
            <div
                className='flex flex-col lg:w-1/2 gap-2'
                onMouseLeave={() => setIsHovered(false)}
            >
                <div>
                    <p className='text-4xl font-bold'>Our Team</p>
                </div>
                {isHovered ?
                    renderTeam()
                    :
                    <></>
                }
                <div
                    className='flex flex-1 flex-row gap-2 justify-between mt-6'
                >
                    {DataTeam.map((team) =>
                        <div className='flex flex-col gap-1 items-center'>
                            <img
                                alt={team.nickname}
                                src={(team.profile as any)[team.nickname] || ""}
                                className='w-24 hover:w-32 cursor-pointer transition-opacity duration-300 ease-in-out rounded-lg'
                                onMouseEnter={() => setHover(team)}
                            />
                            <p>{team.nickname}</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    </>
};

export default About;