import { LogRoute } from "../../types/logRoute";
import { AddLogRouteReq } from "../../types/logRouteContract";
import Api from "../api";

export default async function apiAddLogRoute(data: AddLogRouteReq) {
  const body = JSON.stringify(data);

  return await Api.post<LogRoute>({
    input: "/api/v1/log-route",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
