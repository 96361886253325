
interface mediaProps {
    id: string
}
const MediaImg: React.FC<mediaProps> = ({ id }) => {

    return (
        <div className='w-full'>
            <img
                src={`http://103.186.31.38:58083/api/v1/public/media/${id}`}
                alt="test"
            />
        </div>
    )
}
export default MediaImg