import React, { useEffect, useState } from 'react';
import { LogRoute } from '../../types/logRoute';
import useLogRouteList from '../../hooks/useLogRouteList';
import ActionButtonRow from '../../components/admin/ActionButton';
import { dumpRoute } from '../../dummy/DummyRoute';
import useDumpList from '../../hooks/useDumpList';
import { Dump, DumpType } from '../../types/dump';
import { useAppContext } from '../../contexts/DataContext';
import useAuth from '../../hooks/useAuth';
import { useLoadingContext } from '../../contexts/LoadingContext';

const Task: React.FC = () => {
    const { incrementLoading, decrementLoading } = useLoadingContext()
    const {data: auth} = useAuth()
    const{state} = useAppContext()

    const {
        isLoading: isLoadingLogRoute,
        get: getLogRoute
    } = useLogRouteList()

    const {
        get: getDumpList
    } = useDumpList()

    const [myTask, setMyTask] = useState<LogRoute[]>()

    useEffect(() => {
        isLoadingLogRoute ? incrementLoading() : decrementLoading();
    }, [isLoadingLogRoute])

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100
            }
            if (state.logRoutes.length === 0) getLogRoute(query);

            const dumpQuery = {
                type: DumpType.TempDump,
                limit: 100,
            };
            if (state.dumps.length === 0) await getDumpList(dumpQuery);
        })()
    }, [])

    useEffect(() => {
        setMyTask(dumpRoute.filter((log) => log.driverId === auth.id))
    }, [state.logRoutes])

    const RenderDumpName = (id: string) => {
        const dumpName = (state.dumps as Dump[]).find((dump) => dump.id === id)
        if (dumpName?.id !== "") return dumpName?.name
        return "-"
    }

    const handleViewClick = () => {
        // Add logic for handling view action
        console.log('View Clicked');
    };

    const handleDeleteClick = () => {
        // Add logic for handling delete action
        console.log('Delete Clicked');
    };

    return (
        <div className="flex flex-col container mx-auto p-8 px-12 lg:px-24 py-24 h-screen w-full gap-12">
            <div className='flex flex-col lg:flex-row justify-between w-full'>
                <div className='text-center lg:text-left text-2xl font-bold'>
                    Log Penugasan
                </div>
            </div>
            <div>
                <table className="min-w-full bg-white border border-gray-300">
                    <thead>
                        <tr>
                            <th className="py-2 px-4 border-b">Tanggal</th>
                            <th className="py-2 px-4 border-b">Rute TPS</th>
                            <th className="py-2 px-4 border-b">Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {myTask?.map((item) => (
                            <tr key={item.id}>
                                <td className="py-2 px-4 border-b">{item.createdAt.split("T")[0]}</td>
                                <td className="py-2 px-4 border-b">
                                    <div className='flex flex-flow justify-center gap-1'>
                                        {item.dumpIds.map((dump, index) => (
                                            <span className='p-1 rounded-md shadow-md text-white text-xs bg-basicGreen'>
                                                {RenderDumpName(dump)}
                                            </span>
                                        ))}
                                    </div>
                                </td>
                                <td className="py-2 px-4 border-b">
                                    <ActionButtonRow
                                        view={["DETAIL"]}
                                        onViewClick={handleViewClick}
                                        onDeleteClick={handleDeleteClick}
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                {myTask?.length === 0 ?
                    <div>Data Penugasan tidak ditemukan</div>
                    :
                    <div></div>
                }
            </div>
        </div>
    );
};

export default Task;
