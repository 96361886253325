export interface Mcu {
  id: string;
  createdAt: string;
  updatedAt: string;
  dumpId: string;
}

export const mcuDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  dumpId: "",
} as Mcu;
