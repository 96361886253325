import { Media } from "../../types/media";
import { AddMediaReqForm } from "../../types/mediaContract";
import Api from "../api";

export default async function apiAddMedia(data: AddMediaReqForm) {
  const formData = new FormData();
  formData.append("file", data.file);

  return await Api.post<Media>({
    input: "/api/v1/media",
    init: {
      body: formData,
    },
  });
}
