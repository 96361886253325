import { useEffect, useState } from "react";
import { GetLogReportListReqQuery } from "../types/logReportContract";
import { LogReport } from "../types/logReport";
import apiGetLogReportList from "../api/logReport/apiGetLogReportList";
import { useAppContext } from "../contexts/DataContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useLogReportList = (query?: GetLogReportListReqQuery) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const {dispatch} = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LogReport[]>([]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async (newQuery?: GetLogReportListReqQuery) => {
    if (newQuery) query = newQuery;

    let resData: LogReport[] | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetLogReportList(query);

      setData(res.json.data);
      dispatch({
        type: 'GET',
        payload: {
          type: 'logReports',
          data: res.json.data
        }
      });
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
  };
};

export default useLogReportList;
