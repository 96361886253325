import React, { useEffect, useState } from "react"
import useLogCollection from "../../hooks/useLogCollection"
import useLogCollectionList from "../../hooks/useLogCollectionList"
import { LogCollection, logCollectionDefault } from "../../types/logCollection"
import ModalAdd from "../admin/ModalAddData"
import { AddLogCollectionReq } from "../../types/logCollectionContract"
import { useAppContext } from "../../contexts/DataContext"
import IconClose from "../public/IconClose"

interface collectionProps {
    routeId: string,
    dumpId: string,
    isOpen: boolean,
    onClose: () => void
}

const ReportCollection: React.FC<collectionProps> = ({ routeId, dumpId, isOpen, onClose }) => {
    const {
        add: addCollection,
    } = useLogCollection()

    const {
        get: getCollectionList,
    } = useLogCollectionList()

    const {state} = useAppContext()

    const keys = [
        { title: 'Volume', key: 'volume' },
        { title: 'Status', key: 'status' },
        { title: 'Catatan', key: 'note' }
    ]
    const [hasReported, setHasReported] = useState(false)
    const [newReport, setNewReport] = useState<AddLogCollectionReq>({
        routeId: routeId,
        dumpId: dumpId,
        volume: 0,
        status: "",
        note: "",
    })

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100
            }
            if (state.logCollections.length === 0) getCollectionList(query)
        })()
    }, [])

    useEffect(() => {
        const isReported: LogCollection = (state.logCollections as LogCollection[]).find((log) => log.routeId === routeId && log.dumpId === dumpId) || logCollectionDefault
        setHasReported(isReported.id !== "")
    }, [state.logCollection])

    if (!isOpen) return null

    return (
        <div className="flex flex-col map-overlay">
            <div
                className="bg-white bg-opacity-75 transition-opacity flex justify-end"
                onClick={onClose}
            >
                <span className="text-black font-bold cursor-pointer">
                    <IconClose />
                </span>
            </div>
            <div>
                {hasReported ?
                    <>Data telah diisi</>
                    :
                    <ModalAdd
                        title="Laporan Pengambilan"
                        data={newReport}
                        handleAdd={(data: AddLogCollectionReq)=>addCollection(data)}
                        keys={keys}
                        isOpen={isOpen}
                        onClose={onClose}
                    />
                }
            </div>
        </div>
    )
}
export default ReportCollection