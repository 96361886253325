import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppProvider } from './contexts/DataContext';
import DisplayProvider from './contexts/DisplayContext';
import { AuthProvider } from './contexts/AuthContext';
import { LoadingProvider } from './contexts/LoadingContext';
import { WebSocketProvider } from './contexts/WebSocketContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter basename="/"> {/* Set your basename */}
    <React.StrictMode>
      <LoadingProvider>
        <DisplayProvider>
          <AuthProvider>
            <WebSocketProvider>
              <AppProvider>
                <App />
              </AppProvider>
            </WebSocketProvider>
          </AuthProvider>
        </DisplayProvider>
      </LoadingProvider>
    </React.StrictMode>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
