// TransitionSlide.tsx

import React from 'react';
import { CSSTransition } from 'react-transition-group';

interface TransitionSlideProps {
  children: React.ReactNode;
}

const TransitionSlide: React.FC<TransitionSlideProps> = ({ children }) => (
  <div className="overflow-hidden">
    <CSSTransition
      in={true}
      appear
      timeout={500}
      classNames="slide"
      unmountOnExit
    >
      <div>{children}</div>
    </CSSTransition>
  </div>
);

export default TransitionSlide;
