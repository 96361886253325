import React, { useEffect, useRef, useState } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder";
import "../App.css";

import iconDefault from "../assets/iconDefault.png";
import iconRed from "../assets/iconDefault_red.png";
import iconYellow from "../assets/iconDefault_yellow.png";
import iconGreen from "../assets/iconDefault_green.png";
import ModalDumpInfo from "../components/admin/map/ModalDumpInfo";
import ModalAddRoute from "../components/admin/map/ModalAddRoute";

import SelectMode from "../components/admin/map/SelectMode";
import ModalTaskRoute from "../components/admin/map/ModalTaskRoute";

import useDumpList from "../hooks/useDumpList";
import useSearch from "../hooks/useSearch";
import useFilter from "../hooks/useFilter";
import useLogReportList from "../hooks/useLogReportList";

import { Dump, DumpType, dumpDefault } from "../types/dump";
import { LogReport, logReportDefault } from "../types/logReport";
import Collections from "./admin/collection/Collection";
import { useAppContext } from "../contexts/DataContext";
import useLogDumpList from "../hooks/useLogDumpList";
import { LogDump, logDumpDefault } from "../types/logDump";
import { Link } from "react-router-dom";
import { useLoadingContext } from "../contexts/LoadingContext";
import Pagination from "../components/public/Pagination";
import useRoute from "../hooks/useRoute";
import { useWebSocket } from "../contexts/WebSocketContext";

interface mapProps {
  selectedOpt: string
  searchTerm: string
}
interface DataItem {
  [key: string]: any; // Representasi umum untuk atribut JSON apapun
}
const Mapping: React.FC<mapProps> = ({ selectedOpt, searchTerm }) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { wsRef } = useWebSocket();

  const [notification, setNotification] = useState<DataItem[]>([])

  const { state,dispatch } = useAppContext()

  const [mapType, setMapType] = useState<string>("facility");
  const [trashList, setTrashList] = useState<LogReport[]>([logReportDefault])

  const [volumes, setVolumes] = useState<LogDump[]>([logDumpDefault])
  const [volumeList, setVolumeList] = useState<Dump[]>([dumpDefault])
  const [current, setCurrent] = useState<Dump[]>([dumpDefault])
  const [fromTo, setFromTo] = useState({
    day: "",
    from: "",
    to: "",
  })
  // init data
  const {
    isLoading: isLoadingDumpList,
    get: getDumpList,
  } = useDumpList();

  const {
    isLoading: isLoadingReportList,
    get: getReportList,
  } = useLogReportList();

  const {
    isLoading: isLoadingLogDump,
    get: getLogDumpList
  } = useLogDumpList()

  const {
    data: routeData,
    get: getRoute
  } = useRoute();

  // state show route
  const [isRouteList, setIsRouteList] = useState(false);
  const [showRoute, setShowRoute] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState([""]);

  // state add route modal
  const [addRoute, setAddRoute] = useState(false)

  //state asign route modal
  const [asignRoute, setAsignRoute] = useState(false)

  // state show marker information
  const [showInfo, setShowInfo] = useState(false);
  const [selectedDump, setSelectedDump] = useState<Dump>(dumpDefault);

  // Ref untuk menyimpan referensi peta
  const mapRef = useRef<L.Map | null>(null);

  const sortLocationsByIds = (locationIds: string[]) => {
    return locationIds.map((id) =>
      (state.dumps as Dump[]).find((location) => location.id === id)
    );
  };

  const filterOptions = { keys: ['mapSectorId'] }
  const { handleFilter, data: filterData } = useFilter(state.dumps, filterOptions)

  const searchOptions = { keys: ['name'] };
  const { data: resultData, handleSearch } = useSearch(selectedOpt === "" ? state.dumps : filterData, searchOptions)

  useEffect(() => {
    isLoadingDumpList ? incrementLoading() : decrementLoading();
  }, [isLoadingDumpList])

  useEffect(() => {
    isLoadingLogDump ? incrementLoading() : decrementLoading()
  }, [isLoadingLogDump])

  useEffect(() => {
    isLoadingReportList ? incrementLoading() : decrementLoading();
  }, [isLoadingReportList])

  useEffect(() => {
    (async () => {
      const query = {
        limit: -1,
      };
      if (state.dumps.length === 0) await getDumpList(query);

      const logQuery = {
        limit: 100,
      };
      if (state.logReports.length === 0) await getReportList(logQuery);

      const dumpQuery = {
        unique: true,

      };
      await getLogDumpList(dumpQuery);
    })()

  }, [])

  useEffect(() => {
    // Use WebSocket here, e.g., add event listeners or send messages
    if (wsRef.current?.readyState == 1) {
      wsRef.current.addEventListener('message', addNotification);

      return () => {
        wsRef.current?.removeEventListener("message", addNotification)
      }
    }
  }, [wsRef]);

  const addNotification = (event: MessageEvent<any>) => {
    setNotification((prevNotifications) => [...prevNotifications, event.data])
  }

  useEffect(() => {
    dispatch({
      type: "GET",
      payload: {
        type: "Notif",
        data: notification,
      },
    });
  }, [notification])

  useEffect(() => {
    (async () => {
      const dumpQuery = {
        unique: true,
        from: fromTo.from,
        to: fromTo.to,
      };
      await getLogDumpList(dumpQuery);
      // console.log(fromTo)
      // console.log(state.logDumps)
    })()
  }, [, fromTo])

  const getCapacity = (id: string) => {
    const dump: Dump = (state.dumps as Dump[])?.find((item) => item.id === id) || dumpDefault
    return dump.capacity
  }

  useEffect(() => {
    if (!isLoadingLogDump && state.logDumps.length !== 0 && state.dumps.length !== 0) {
      const today = new Date().toISOString().split("T")[0]
      const volumes: LogDump[] = (state.logDumps as LogDump[]).filter((report) => report.createdAt.split("T")[0] === today)
      setVolumes(volumes.sort((a, b) => (b.measuredVolume / getCapacity(b.dumpId)) - (a.measuredVolume / getCapacity(a.dumpId))))
      
      // Memfilter dump yang terdapat pada logDump
      const filteredDumpArray = volumes.map(logDump => {
        const dumpId = logDump.dumpId;
        const foundDump = (state.dumps as Dump[])?.find(dump => dump.id === dumpId);
        if (foundDump) return foundDump;
      });

      // Menyaring array hasil filter untuk menghapus nilai yang null atau undefined
      const dumpVolumeList: Dump[] = (filteredDumpArray as Dump[]).filter(dump => dump);
      setVolumeList(dumpVolumeList)
    }
  }, [state.logDumps])

  useEffect(() => {
    if (!isLoadingReportList && state.logReports.length !== 0) {
      const trashes = (state.logReports as LogReport[]).filter((report) => report.type === "Sampah Berserakan")
      setTrashList(trashes)
    }
  }, [state.logReports])

  // init get data and get map, also re-init marker and route here
  useEffect(() => {
    // Hancurkan peta jika sudah ada
    if (mapRef.current) {
      mapRef.current.remove();
    }

    // Inisialisasi peta
    const map = L.map("map").setView(
      [-7.798484558411561, 110.3724409997804],
      13
    );
    // const map = L.map('map').setView([-7.798484558411561, 110.3724409997804], 13).addLayer(polyline);

    const key = 'tDMeMZZ9lS5QizYGtekk'
    // Menambahkan tile layer dari OpenStreetMap
    L.tileLayer(`https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${key}`, {
      attribution: "© B-COP",
    }).addTo(map);

    // init search result when searchterm filled
    if (searchTerm.length !== 0) { handleSearch(searchTerm) }

    // init filter result
    if (selectedOpt !== "") { handleFilter(selectedOpt) }

    // Menambahkan marker
    const dataMarker = mapType === "facility" ? !showRoute ? searchTerm.length === 0 ? selectedOpt === "" ? state.dumps : filterData : resultData : sortLocationsByIds(selectedRoute) : mapType === "trash" ? trashList : volumeList;


    (dataMarker as Dump[])?.forEach((loc) => {
      if (loc) {
        const volume: LogDump = volumes.find((item) => item.dumpId === loc.id) || logDumpDefault
        const percentage = volume?.measuredVolume / loc.capacity

        const marker = L.marker([loc.coordinate.latitude, loc.coordinate.longitude], {
          icon: L.icon({
            iconUrl: mapType === "area" ? percentage < 0.4 ? iconGreen : percentage < 0.7 ? iconYellow : iconRed : iconDefault,
            iconSize: [32, 32], // Set the size of your icon
            iconAnchor: [16, 32], // Adjust the anchor point if needed
            popupAnchor: [0, -32], // Adjust the popup anchor if needed
          }),
          interactive: true,
          zIndexOffset: 1000,
        }).addTo(map);
        // Menambahkan event listener untuk klik pada setiap marker
        marker.on("click", () => {
          // Handle klik event di sini, misalnya, tampilkan informasi atau ubah state
          if (mapType === "facility") {
            setSelectedDump(loc);
            setShowInfo(true);
          }
        });
      }
    });

    // Create a Leaflet polyline to represent the route
    if (showRoute) {
      const sortedLocations = sortLocationsByIds(selectedRoute);

      const dumpLocationCoordinates: L.Routing.Waypoint[] = sortedLocations
        .filter((location) => location !== null) // Filter out null values
        .map((location) => ({
          latLng: L.latLng(
            location!.coordinate.latitude,
            location!.coordinate.longitude
          ),
          name: "Optional Location Name", // You can provide a name for the waypoint
          // Add other properties as needed for your use case
        }));
      const osrmEndpoint = "https://router.project-osrm.org/route/v1/";

      L.Routing.control({
        waypoints: dumpLocationCoordinates,
        routeWhileDragging: false,
        router: new L.Routing.OSRMv1({
          serviceUrl: osrmEndpoint,
          requestParameters: {
            overview: false,
            alternatives: true,
            steps: true,
          },
        }),
      })
        .addTo(map)
        .on("routesfound", function (e) {
          var routes = e.routes;

          // memproses data rute sesuai kebutuhan Anda
        })
        .on("routingerror", function (e) {
          console.error("Routing error:", e.error);
        });
    }

    // Simpan referensi peta
    mapRef.current = map;
  }, [showRoute, state.dumps, resultData, filterData, searchTerm, selectedOpt, mapType]); // Dependensi kosong, sehingga efek ini hanya dijalankan sekali saat komponen dipasang

  const getVolume = (id: string) => {
    const volume: LogDump = volumes.find((item) => item.dumpId === id) || logDumpDefault
    return volume.measuredVolume.toFixed(3)
  }

  const getPercentage = (dump: Dump) => {
    const volume: LogDump = volumes.find((item) => item.dumpId === dump.id) || logDumpDefault
    return ((volume.measuredVolume / dump.capacity) * 100).toFixed(0)
  }

  const getColorClass = (percentage: string) => {
    const percent = parseInt(percentage)
    if (percent > 70) {
      return 'text-red-500'; // Contoh warna hijau untuk nilai di atas atau sama dengan 80%
    } else if (percent >= 40) {
      return 'text-yellow-500'; // Contoh warna kuning untuk nilai di atas atau sama dengan 60%
    } else {
      return 'text-green-500'; // Contoh warna merah untuk nilai di bawah 60%
    }
  };

  const getColorBorder = (percentage: string) => {
    const percent = parseInt(percentage)
    if (percent > 70) {
      return 'border-red-500'; // Contoh warna hijau untuk nilai di atas atau sama dengan 80%
    } else if (percent >= 40) {
      return 'border-yellow-500'; // Contoh warna kuning untuk nilai di atas atau sama dengan 60%
    } else {
      return 'border-green-500'; // Contoh warna merah untuk nilai di bawah 60%
    }
  };

  const getTime = (day: string) => {
    var _from = new Date()
    const _to = new Date

    if (day === "pagi") {
      _from.setHours(0, 0, 0)
      _to.setHours(12, 0, 0)
    }
    else {
      _from.setHours(12, 0, 0)
      _to.setHours(0, 0, 0)
    }

    return { from: _from.toISOString(), to: _to.toISOString() }
  }

  return (
    <div className="flex-1 flex flex-col lg:flex-row rounded-2xl p-2 shadow-md sm:mx-8 mt-20 my-2 h-full lg:h-screen">
      <div className="w-full lg:w-1/4 flex flex-col justify-between py-8">
        <div>
          <p className="font-bold text-lg">Log Volume Sampah</p>
        </div>
        <div>
          <select
            value={fromTo.day}
            onChange={(e) => {
              setFromTo({
                day: e.target.value,
                from: getTime(e.target.value).from,
                to: getTime(e.target.value).to
              })
            }}
          >
            <option value={"pagi"}>Pagi</option>
            <option value={"siang"}>Siang</option>
          </select>
        </div>
        <div className="h-full p-4">
          {volumeList.length !== 0 ?
            <div className="flex flex-col justify-between h-full">
              <div className="flex flex-col gap-2">
                {current.map((item) =>
                  <div className={`bg-white border-2 ${getColorBorder(getPercentage(item))} rounded-lg p-1 flex flex-row gap-4`}>
                    <div
                      className={`text-lg font-bold w-1/4 ${getColorClass(getPercentage(item))}`}
                    >
                      {getPercentage(item)} %
                    </div>
                    <div className="flex flex-col text-left w-3/4">
                      <p>{item.name}</p>
                      <p className="text-sm">{getVolume(item.id)} m<sup>3</sup></p>
                    </div>
                  </div>
                )}
              </div>
              <Pagination data={volumeList} setCurrentData={(current: Dump[]) => setCurrent(current)} />
            </div>
            :
            <p>Belum ada data volume hari ini</p>
          }
        </div>
        <div>
          <Link to="/dataview/log-garbages-volume">
            <p className="font-bold text-xs cursor-pointer text-orange">...lihat semua log volume</p>
          </Link>
        </div>
      </div>
      <div id="map" className="relative w-full flex-1 rounded-lg">
        <SelectMode
          mapType={mapType}
          setMapType={(type: string) => setMapType(type)}
        />
        <div
          onClick={!showRoute ? () => setIsRouteList((prev) => !prev) : () => setShowRoute((prev) => !prev)}
          className="h-min absolute bottom-10 right-5 z-[1000] bg-orange p-2 px-6 rounded-md shadow-lg text-white font-bold text-lg cursor-pointer"
        >
          <p>{!showRoute ? "Lihat" : "Sembunyikan"} Rute</p>
        </div>
        {showInfo && (
          <ModalDumpInfo
            isOpen={showInfo}
            onClose={() => setShowInfo((prev) => !prev)}
            data={selectedDump}
          />
        )}
      </div>
      <ModalTaskRoute
        isOpen={isRouteList}
        onClose={() => setIsRouteList((prev) => !prev)}
        onChoose={() => setShowRoute((prev) => !prev)}
        onAdd={() => setAddRoute((prev) => !prev)}
        setSelected={(ids: string[]) => setSelectedRoute(ids)}
      />
      <ModalAddRoute
        isOpen={addRoute}
        getRoute={(id) => getRoute({ finalDumpId: id })}
        onClose={() => setAddRoute(false)}
        onSubmit={() => setAsignRoute(true)}
        dumpListData={(state?.dumps as Dump[]).filter((dump) => dump.type === DumpType.FinalDump)}
      />
      <Collections isOpen={asignRoute} routeData={routeData} onClose={() => setAsignRoute(false)} />
    </div>
  );
};

export default Mapping;
