import React from 'react';

interface selectProps{
    mapType: string,
    setMapType: (type:string)=>void
}

const SelectMode: React.FC<selectProps> = ({mapType, setMapType}) => {

    return (
        <div className="absolute top-5 right-5 z-[1000] h-min bg-white p-2 rounded-md shadow-lg">
            <p>Pilih Mode Peta</p>
            <select 
                className='py-2 px-4 rounded-md text-lg'
                value={mapType}
                onChange={(e)=>{setMapType(e.target.value)}}
            >
                <option value="facility">Peta Fasilitas</option>
                <option value="trash">Sampah Berserakan</option>
                <option value="area">Persebaran Sampah</option>
                {/* Add more options as needed */}
            </select>
        </div>
    );
};

export default SelectMode;
