export interface LogRoute {
  id: string;
  createdAt: string;
  updatedAt: string;
  driverId: string;
  truckId: string;
  dumpIds: string[];
}

export const logRouteDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  driverId: "",
  truckId: "",
  dumpIds: [],
} as LogRoute;
