import { useEffect, useState } from "react";
import { GetMcuListReqQuery } from "../types/mcuContract";
import { Mcu } from "../types/mcu";
import apiGetMcuList from "../api/mcu/apiGetMcuList";
import { useAppContext } from "../contexts/DataContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useMcuList = (query?: GetMcuListReqQuery) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const {dispatch} = useAppContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Mcu[]>([]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async (newQuery?: GetMcuListReqQuery) => {
    if (newQuery) query = newQuery;

    let resData: Mcu[] | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetMcuList(query);

      setData(res.json.data);
      dispatch({
        type: 'GET',
        payload: {
          type: 'MCUs',
          data: res.json.data
        }
      });
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
  };
};

export default useMcuList;
