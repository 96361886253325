import { Dump } from "../../types/dump";
import { AddDumpReq } from "../../types/dumpContract";
import Api from "../api";

export default async function apiAddDump(data: AddDumpReq) {
  const body = JSON.stringify(data);

  return await Api.post<Dump>({
    input: "/api/v1/dump",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
