import { LogReport } from "../../types/logReport";
import { UpdateLogReportReq } from "../../types/logReportContract";
import Api from "../api";

export default async function apiUpdateLogReport(
  id: string,
  data: UpdateLogReportReq
) {
  const body = JSON.stringify(data);

  return await Api.patch<LogReport>({
    input: `/api/v1/log-report/${id}`,
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
