import { UserRole } from "./user";

export interface Auth {
  token: string;
  id: string;
  name: string;
  role: UserRole;
}

export const authDefault = {
  token: "",
  id: "",
  name: "",
  role: "",
} as Auth;