import pp from '../assets/pp.jpeg'
import Najla from '../assets/timA03 (3).jpg'
import Hilmy from '../assets/timA03 (1).jpg'
import Rio from '../assets/timA03 (2).jpg'
import Alvin from '../assets/timA03 (4).jpg'
import Adit from '../assets/timA03 (1).png'

export const DataTeam = [
    {
        id: "20/463247/TK/51239",
        name: "Najla Andini Aprilla Umar",
        nickname:"Najla",
        study: "Teknik Elektro",
        quotes: "Hi, im using whatsapp!",
        profile: {Najla}
    },
    {
        id: "20/460535/TK/51124",
        name: "Aditya Ramadhan",
        nickname:"Adit",
        study: "Teknologi Informasi",
        quotes: "Daijobu deshou, datte kimi..., yowaimo",
        profile: {Adit}
    },
    {
        id: "20/460184/TK/50773",
        name: "Muhammad Alvin",
        nickname:"Alvin",
        study: "Teknik Elektro",
        quotes: "Seni adalah ledakan, ledakan adalah duarr kaboom",
        profile: {Alvin}
    },
    {
        id: "20/460182/TK/50771",
        name: "Muhammad Naufal Hilmy Makarim",
        nickname:"Hilmy",
        study: "Teknik Elektro",
        quotes: "Seni adalah ledakan, ledakan adalah duarr kaboom",
        profile: {Hilmy}
    },
    {
        id: "20/463251/TK/51243",
        name: "Priyo Nurmanto",
        nickname:"Rio",
        study: "Teknik Elektro",
        quotes: "Bismillah PLN ⚡⚡⚡",
        profile: {Rio}
    }
]