import { Coordinate } from "./coordinate";

export interface Dump {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  mapSectorId: string;
  coordinate: Coordinate;
  type: DumpType;
  capacity: number;
  condition: string;
}

export enum DumpType {
  _ = "",
  TempDump = "TEMP_DUMP",
  FinalDump = "FINAL_DUMP",
}

export const dumpDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  name: "",
  mapSectorId: "",
  coordinate: {
    latitude: 0,
    longitude: 0,
  },
  type: "",
  capacity: 0,
  condition: "",
} as Dump;
