import { useEffect } from "react"
import useDumpList from "../../hooks/useDumpList"
import { Mcu } from "../../types/mcu"
import ActionButtonRow from "./ActionButton"
import { useAppContext } from "../../contexts/DataContext"
import { Dump, dumpDefault } from "../../types/dump"
interface mcuProps{
    data: Mcu
}
const MCUCard: React.FC<mcuProps> = ({data}) => {
    const {get:getDumpList} = useDumpList()
    const {state}= useAppContext();
    useEffect(()=>{
        (async()=>{
            const query = {limit:100}
            if(state.dumps.length===0)await getDumpList(query)
        })()
    },[])
    const getTPS = (id:string)=>{
        const dump: Dump = (state.dumps as Dump[]).find((item)=>item.id===id) || dumpDefault
        return dump.name
    }
    return (
        <div className="w-full flex flex-row gap-2 justify-between rounded-xl shadow-md p-2 px-6 border-2 border-basicGreen items-center">
            <div className="flex flex-col gap-1 text-left">
                <p className="font-bold text-xl">{data.id}</p>
                <div className="flex flex-row gap-4 justify-between">
                    <p>TPS: {getTPS(data.dumpId)}</p>
                    |
                    <p>Model: ESP-32</p>
                    |
                    <p>Status: OK</p>
                </div>
            </div>
            <div className="cursor-pointer text-orange font-bold">
                <ActionButtonRow
                    view={["DETAIL"]}
                />
            </div>
        </div>
    )
}
export default MCUCard