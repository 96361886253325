import { LogReport } from "../../types/logReport";
import { GetLogReportListReqQuery } from "../../types/logReportContract";
import Api from "../api";

export default async function apiGetLogReportList(
  query?: GetLogReportListReqQuery
) {
  return await Api.get<LogReport[]>({
    input: "/api/v1/log-reports",
    query,
  });
}
