export interface User {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  username: string;
  role: UserRole;
}

export enum UserRole {
  _ = "",
  Admin = "ADMIN",
  Janitor = "JANITOR",
}

export const userDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  name: "",
  username: "",
  role: "",
} as User;
