// ActionButtonRow.tsx

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTrash } from '@fortawesome/free-solid-svg-icons';

interface ActionButtonRowProps {
  view: string[]
  onViewClick?: () => void;
  onDeleteClick?: () => void;
}

const ActionButtonRow: React.FC<ActionButtonRowProps> = ({ view, onViewClick, onDeleteClick }) => {
  return (
    <div className="flex justify-center items-center space-x-4">
      {/* Open Detail Button */}
      {view.includes("DETAIL") ?
        <button
          className="bg-blue hover:bg-lightblue text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-blue"
          onClick={onViewClick}
        >
          <FontAwesomeIcon icon={faEye} className="mr-2" />
          Detail
        </button>
        :
        <></>
      }

      {/* Delete Button */}
      {view.includes("DELETE") ?
        <button
          className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-red"
          onClick={onDeleteClick}
        >
          <FontAwesomeIcon icon={faTrash} className="mr-2" />
          Delete
        </button>
        :
        <></>
      }
    </div>
  );
};

export default ActionButtonRow;
