import React, { useEffect, useState } from 'react';
import MiniMap from './MiniMap';
import useMapSectorList from '../../hooks/useMapSectorList';
import { DumpType } from '../../types/dump';
import { UserRole } from '../../types/user';
import MediaImg from './MediaImg';
import IconClose from '../public/IconClose';

interface DetailModalProps<T> {
    isOpen: boolean;
    isLog: boolean;
    onClose: () => void;
    keys: { title: string, key: string }[]
    data: T;
    onUpdate?: (data: any) => void
}

const ModalDetail: React.FC<DetailModalProps<any>> = ({ isOpen, isLog, onClose, keys, data, onUpdate }) => {
    const [isEdit, setisEdit] = useState(false)
    const [updateData, setUpdateData] = useState<typeof data>(data);

    const closeDetail = () => {
        setisEdit(false)
        onClose()
    }
    const saveDetail = () => {
        if (onUpdate) onUpdate(updateData)
        closeDetail()
    }

    useEffect(() => {
        setUpdateData(data)
    }, [data])

    const {
        data: sectorListData,
        get: getSectorList,
    } = useMapSectorList();

    useEffect(() => {
        (async () => {
            const sectorQuery = {
                limit: 100,
            };
            if (sectorListData.length === 0) await getSectorList(sectorQuery);
        })()
    }, [])

    if (!isOpen) {
        return null;
    }

    const getSector = (id: string) => {
        const sector = sectorListData.filter((item) => item.id === id)

        if (sector.length > 0) { return sector[0].name }
        else { return '' }
    }

    const renderTypeInput = (key: string | number) => {
        if (typeof key === "number") { return 'number' }
        else { return 'text' }
    }

    const renderInput = () => {
        return (
            <div className='w-full flex flex-col justify-between gap-2'>
                {keys.map((item, index) => (
                    <div className='w-full flex flex-row gap-2 items-center'>
                        <div className='flex flex-row w-1/4 justify-between text-sm'>
                            <p>{item.title}</p>
                            <p>:</p>
                        </div>
                        {item.key === 'type' && updateData.coordinate ?
                            <select
                                value={updateData[item.key]}
                                onChange={(e) => {
                                    // const value = e.target.value === DumpType.FinalDump ? DumpType.FinalDump : e.target.value === DumpType.TempDump ? DumpType.TempDump : DumpType._;
                                    const value: DumpType = e.target.value as DumpType
                                    setUpdateData((prevData: any) => ({ ...prevData, [item.key]: value }))
                                }}
                            >
                                <option value="" disabled>
                                    Pilih opsi
                                </option>
                                {(Object.keys(DumpType) as Array<keyof typeof DumpType>).map((key) => (
                                    <option value={DumpType[key]} key={key} >
                                        {DumpType[key]}
                                    </option>
                                ))}
                            </select>
                            :
                            item.key === 'mapSectorId' ?
                                <select
                                    value={sectorListData?.find((sector) =>
                                        sector.id === updateData[item.key]
                                    ) || updateData[item.key]}
                                    onChange={(e) => { setUpdateData((prevData: any) => ({ ...prevData, [item.key]: e.target.value })) }}
                                >
                                    <option value="" disabled>
                                        Select Sector
                                    </option>
                                    {sectorListData?.map((sector) =>
                                        <option value={sector.id}>{sector.name}</option>
                                    )}
                                </select>
                                :
                                item.key === 'role' ?
                                    <input
                                        type={renderTypeInput(updateData[item.key])}
                                        className='border-2 w-full rounded-md'
                                        placeholder={item.title}
                                        value={UserRole.Janitor}
                                        disabled
                                    />
                                    :
                                    item.key === 'isActive' ?
                                        <div className="flex flex-row gap-2 justify-between">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    checked={updateData[item.key] === true}
                                                    onChange={(e) => {
                                                        const value: boolean = e.target.value === "1" ? true : false
                                                        setUpdateData((prevData: any) => ({ ...prevData, [item.key]: value }))
                                                    }}
                                                />
                                                Yes
                                            </label>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="2"
                                                    checked={updateData[item.key] === false}
                                                    onChange={(e) => {
                                                        const value: boolean = e.target.value === "1" ? true : false
                                                        setUpdateData((prevData: any) => ({ ...prevData, [item.key]: value }))
                                                    }}
                                                />
                                                No
                                            </label>
                                        </div>
                                        :
                                        <input
                                            type={renderTypeInput(updateData[item.key])}
                                            className='border-2 w-full rounded-md'
                                            placeholder={item.title}
                                            value={updateData[item.key]}
                                            onChange={
                                                (e) => {
                                                    const value = e.target.value;
                                                    setUpdateData((prevData: any) => ({ ...prevData, [item.key]: renderTypeInput(updateData[item.key]) === 'number' ? parseInt(value, 10) : value }))
                                                }
                                            }
                                        />
                        }
                    </div>
                ))}
            </div>
        )
    }

    const renderDetail = () => {
        // Tambahkan logika untuk menentukan jenis data dan merender detail sesuai jenis data
        if (data !== null) {
            // Detail untuk jenis data Dump
            return (
                <>
                    {
                        !isEdit ?
                            <div className='w-full flex flex-col justify-between gap-2 text-left '>
                                <p className='font-bold'>Detail View</p>
                                {keys.map((item, index) => (
                                    <div className='flex flex-row gap-2 w-full items-center'>
                                        <div className='flex flex-row w-1/4 justify-between text-sm'>
                                            <p>{item.key}</p>
                                            <p>:</p>
                                        </div>
                                        <p>{item.key === "mapSectorId" ? getSector(data[item.key]) : item.key === "createdAt" ? data[item.key].split("T")[0] : data[item.key]}</p>
                                    </div>
                                ))}
                            </div>
                            :
                            <div className='w-full flex flex-col justify-between gap-2'>
                                Edit Data
                                {renderInput()}
                            </div>
                    }
                </>
            )

        }
        return null;
    };

    return (
        <div className="fixed top-0 left-0 w-screen h-screen flex flex-col bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="mt-[5.5rem] min-h-0 flex-1">
                <div className="ml-auto w-full h-full bg-white shadow-md p-8 rounded-md max-w-md flex flex-col gap-4 border-2 border-blue">
                    <button className="text-black w-8 h-8 ml-auto flex items-center justify-center rounded-full" onClick={onClose}>
                        <IconClose />
                    </button>
                    <div className="overflow-y-auto">
                        {data['coordinate'] ?
                            <MiniMap coordinate={data.coordinate} isEditable={false} />
                            :
                            <></>
                        }
                        {renderDetail()}
                        {!isLog ?
                            !isEdit ?
                                <div className="bg-gray-200 py-3 sm:flex sm:flex-row-reverse sm:justify-start gap-2">
                                    <button
                                        type="button"
                                        className="bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-red"
                                    >
                                        Delete
                                    </button>

                                    <button
                                        type="button"
                                        className="bg-orange hover:bg-amber-600 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-orange"
                                        onClick={() => setisEdit((prev) => !prev)}
                                    >
                                        Edit
                                    </button>
                                </div>
                                :
                                <div className="bg-gray-200 py-3 sm:flex sm:flex-row-reverse sm:justify-start gap-2">
                                    <button
                                        type="button"
                                        className="bg-blue hover:bg-cyan-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-red"
                                        onClick={saveDetail}
                                    >
                                        Simpan
                                    </button>

                                    <button
                                        type="button"
                                        className="bg-white hover:bg-red-500 hover:text-white text-red-500 border-2 border-red-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline-orange"
                                        onClick={() => setisEdit((prev) => !prev)}
                                    >
                                        Batal
                                    </button>
                                </div>
                            :
                            <></>
                        }
                        {data['mediaId'] ?
                            <MediaImg id={data['mediaId']} />
                            :
                            <></>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalDetail;
