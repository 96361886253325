import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png'

const Dashboard: React.FC = () => {
    const navigate = useNavigate()
    const toReport = () => {
        navigate('/pelaporan')
    }
    return <>
        <div
            className='flex flex-1 flex-col justify-between items-center bg-cover bg-center min-h-screen bg-blockImage lg:bg-fullImage py-12'
        >
            <div className='flex flex-col lg:flex-row px-6 lg:px-24 py-12 pt-24 gap-6 justify-between w-full items-center'>
                <div className='flex flex-col lg:flex-row items-center gap-4'>
                    <img
                        src={logo}
                        className='w-24 hidden lg:block'
                    />
                    <div className='font-bold text-orange lg:text-left'>
                        <p className='text-7xl'>B-COP</p>
                        <p className='text-3xl'>Bin Collection Optimization</p>
                    </div>
                </div>
                <div className='flex flex-col gap-4'>
                    <div className='font-bold text-xl lg:text-left text-basicGreen'>
                        <p>Kondisi sampah atau fasilitas TPS Bermasalah?</p>
                    </div>
                    <div
                        className='bg-orange rounded-2xl px-2 py-4 text-white font-bold text-xl cursor-pointer shadow-md
                                    hover:bg-white hover:border-2 hover:border-orange hover:text-orange'
                        onClick={toReport}
                    >
                        <p>LAPORAN PENGADUAN</p>
                    </div>
                </div>
            </div>
            <div>

            </div>
        </div>
    </>
};

export default Dashboard;