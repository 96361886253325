// ModalOverlay.tsx
import React, { useEffect, useState } from 'react';
import { dumpRoute } from '../../../dummy/DummyRoute';
import useLogRouteList from '../../../hooks/useLogRouteList';
import { useAppContext } from '../../../contexts/DataContext';
import { LogRoute, logRouteDefault } from '../../../types/logRoute';
import useTruckList from '../../../hooks/useTruckList';
import { Truck, truckDefault } from '../../../types/truck';

interface ModalOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    onChoose: () => void;
    onAdd: () => void;
    setSelected: (ids: string[]) => void;
}

const ModalTaskRoute: React.FC<ModalOverlayProps> = ({ isOpen, onClose, onChoose, onAdd, setSelected }) => {
    const [routeToday, setRouteToday] = useState<LogRoute[]>([])
    const { state, dispatch } = useAppContext()
    const {
        get: getLogRoute,
        isLoading: isLoadingLogRoute
    } = useLogRouteList()

    const {
        get: getTrucks
    } = useTruckList()

    useEffect(() => {
        (async () => {
            if (state.logRoutes.length === 0) await getLogRoute({ limit: -1 });
            if(state.trucks.length==0) await getTrucks({limit:-1})
        })()
    }, [])

    useEffect(() => {
        if (!isLoadingLogRoute) {
            const today = new Date().toISOString().split("T")[0]
            const todayRoute: LogRoute[] = (state.logRoutes as LogRoute[]).filter((item) => item.createdAt.split("T")[0] === today)
            setRouteToday(todayRoute)
            dispatch({
                type: 'GET',
                payload: {
                    type: 'todayRoutes',
                    data: todayRoute
                }
            });
        }
    }, [state.logRoutes])

    const chooseRoute = (index: number) => {
        onChoose()
        onClose()
        setSelected((state.logRoutes as LogRoute[])[index].dumpIds)
    }
    const addRoute = () => {
        onAdd()
        onClose()
    }

    const getTruckId=(id:string)=>{
        const truck: Truck = (state.trucks as Truck[]).find((item)=>item.id===id) || truckDefault
        return truck.licensePlate
    }

    if (!isOpen) return null
    return (
        <div
            className={`fixed inset-0 overflow-y-auto z-[1000]`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                ></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-labelledby="modal-title"
                >
                    <div className="bg-white p-4">
                        <h3 className="text-lg font-medium text-gray-900" id="modal-title">
                            Daftar Rute
                        </h3>
                    </div>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                <ul className='flex flex-col gap-2 w-full'>
                                    {routeToday.length !== 0 ? (routeToday as LogRoute[]).map((item, index) => (
                                        <li
                                            onClick={(e) => chooseRoute(index)}
                                            key={index}
                                            className='cursor-pointer px-2 py-2 bg-white border-2 border-basicGreen text-basicGreen rounded-md w-full flex justify-between'
                                        >
                                            <p>{item.createdAt.split("T")[0]} | Truck ID: {getTruckId(item.truckId)} </p>
                                            <p className='font-bold'>	&#8594;</p>
                                        </li>
                                    )) : !isLoadingLogRoute && <p className="text-center">Tidak ada rute ditemukan</p>}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-between">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border-2 border-red-500 shadow-sm px-4 py-2 bg-white text-base font-medium text-red-500 hover:bg-red-500 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={onClose}
                        >
                            Close
                        </button>

                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-basicGreen text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={addRoute}
                        >
                            Buat Rute
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalTaskRoute;
