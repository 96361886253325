import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import Pagination from '../../../components/public/Pagination';
import useSearch from '../../../hooks/useSearch';
import ModalDetail from '../../../components/admin/ModalDetail';
import ModalAdd from '../../../components/admin/ModalAddData';

import useDumpList from '../../../hooks/useDumpList';
import useDump from '../../../hooks/useDump';
import { Dump, DumpType } from '../../../types/dump';
import { AddDumpReq, UpdateDumpReq } from '../../../types/dumpContract';
import useMapSectorList from '../../../hooks/useMapSectorList';

import { useAppContext } from '../../../contexts/DataContext';
import ModalDelete from '../../../components/admin/ModalDelete';

interface dumpProps {
  searchTerm: string
}

const DataDump: React.FC<dumpProps> = ({ searchTerm }) => {
  const {
    isLoading: isLoadingDumpList,
    get: getDumpList,
  } = useDumpList();

  const {
    data: sectorListData,
    get: getSectorList,
  } = useMapSectorList();

  const { state } = useAppContext();

  useEffect(() => {
    (async () => {
      const query = {
        limit: 100,
      };
      if (state.dumps.length === 0) await getDumpList(query);
      const sectorQuery = {
        limit: 100,
      };
      if (sectorListData.length === 0) await getSectorList(sectorQuery);
    })()
  }, [])

  //init pagination data
  const [currentData, setCurrentData] = useState<Dump[]>([])

  //search handle
  const searchOptions = { keys: ['name'] };
  const { data: resultData, handleSearch } = useSearch(state.dumps, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const dataview = searchTerm.length === 0 ? currentData : resultData;

  const keys = [
    { title: 'Nama', key: 'name' },
    { title: 'Sektor peta', key: 'mapSectorId' },
    { title: 'Tipe', key: 'type' },
    { title: 'Kapasitas', key: 'capacity' },
    { title: 'Kondisi', key: 'condition' }
  ];

  // selected data and id state
  const [selectedData, setSelectedData] = useState<any>(null);
  const {
    add: addDump,
    update: updateDump,
    remove: removeDump
  } = useDump(selectedData?.id);

  //modal detail handling
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (data: any) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedData(null);
    setIsModalOpen(false);
  };

  //modal add handling
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [addData, setAddData] = useState<AddDumpReq>({
    name: "",
    mapSectorId: "",
    coordinate: {
      latitude: 0,
      longitude: 0,
    },
    type: DumpType.TempDump,
    capacity: 0,
    condition: ""
  })
  const openAdd = () => {
    setIsAddOpen(true)
  }
  const closeAdd = () => {
    setIsAddOpen(false)
  }

  // modal update handling

  // modal delete handling
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const openDelete = (data: any) => {
    setSelectedData(data);
    setIsDeleteOpen(true)
  }
  const closeDelete = () => {
    setIsDeleteOpen(false)
  }

  const getSector = (key: string) => {
    const sector = sectorListData.filter((item) => item.id === key)

    if (sector.length > 0) { return sector[0].name }
    else { return '' }
  }

  return (
    <div className="container mx-auto mt-24 flex flex-col gap-4">
      <div className='flex flex-row justify-between'>
        <div className='text-2xl font-bold'>
          Data TPS/TPA
        </div>
        <div>
          <button
            className='px-2 py-2 bg-orange text-white font-bold rounded-md shadow-md'
            onClick={openAdd}
          >
            + Tambah TPS/TPA
          </button>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Nama</th>
            <th className="py-2 px-4 border-b">Sector</th>
            <th className="py-2 px-4 border-b">Kapasitas (m<sup>3</sup>)</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.name}</td>
              <td className="py-2 px-4 border-b">{getSector(item.mapSectorId)}</td>
              <td className="py-2 px-4 border-b">{item.capacity}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow
                  view={["DETAIL", "DELETE"]}
                  onViewClick={() => openModal(item)}
                  onDeleteClick={() => openDelete(item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.dumps.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={state.dumps} setCurrentData={(current: Dump[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
      <ModalAdd title="Tambah TPS" isOpen={isAddOpen} onClose={closeAdd} keys={keys} data={addData} handleAdd={(data: AddDumpReq) => addDump(data)} />
      <ModalDetail isOpen={isModalOpen} isLog={false} onClose={closeModal} keys={keys} data={selectedData} onUpdate={(data: UpdateDumpReq) => updateDump(data)} />
      <ModalDelete isOpen={isDeleteOpen} onClose={closeDelete} data={selectedData} onDelete={removeDump} />
    </div>
  );
};

export default DataDump;
