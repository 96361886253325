import { User } from "../../types/user";
import { UpdateUserReq } from "../../types/userContract";
import Api from "../api";

export default async function apiUpdateUser(id: string, data: UpdateUserReq) {
  const body = JSON.stringify(data);

  return await Api.patch<User>({
    input: `/api/v1/user/${id}`,
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
