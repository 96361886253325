import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useLogRouteList from '../../hooks/useLogRouteList';
import { useAppContext } from '../../contexts/DataContext';
import { dumpRoute } from '../../dummy/DummyRoute';
import { LogRoute, logRouteDefault } from '../../types/logRoute';
import useDumpList from '../../hooks/useDumpList';
import { Dump, DumpType } from '../../types/dump';
import useAuth from '../../hooks/useAuth';
import { useLoadingContext } from '../../contexts/LoadingContext';

const Janitor: React.FC = () => {
    const { incrementLoading, decrementLoading } = useLoadingContext()
    const [taskToday, setTaskToday] = useState<LogRoute>(logRouteDefault)
    const {data: auth} = useAuth()

    const {
        isLoading: isLoadingLogRoute,
        get: getLogRouteList
    } = useLogRouteList()

    const {
        get: getDumpList
    } = useDumpList()

    const { state } = useAppContext()

    useEffect(() => {
        isLoadingLogRoute ? incrementLoading() : decrementLoading();
    }, [isLoadingLogRoute])

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100,
            };
            if (state.logRoutes.length === 0) await getLogRouteList(query);

            const dumpQuery = {
                type: DumpType.TempDump,
                limit: 100,
            };
            if (state.dumps.length === 0) await getDumpList(dumpQuery);
        })()
    }, [])

    useEffect(() => {
        const today = new Date().toISOString().split("T")[0]
        const todayTask = dumpRoute.find((route) => {
            return route.createdAt && route.createdAt.split("T")[0] === today;
        })
        setTaskToday(todayTask || logRouteDefault)
    }, [state.logRoutes])

    const RenderDumpName = (id: string) => {
        const dumpName = (state.dumps as Dump[]).find((dump) => dump.id === id)
        if (dumpName?.id !== "") return dumpName?.name
        return "-"
    }


    return (
        <div className='bg-cover bg-center min-h-screen bg-blockImage lg:bg-fullImage py-12'>
            <div className="flex flex-col container mx-auto px-6 lg:px-24 py-24 h-screen">
                <div className='flex flex-col justify-center items-center gap-4 w-full'>
                    <div className='text-center lg:text-left text-2xl font-bold'>
                        Hai, {auth.name}
                    </div>
                    <div className='flex flex-col p-2 shadow-md rounded-lg bg-white border-2 border-orange w-full lg:w-1/2'>
                        <div className='flex flex-col gap-6'>
                            <p className='text-xl font-bold text-orange'>NOTIFIKASI</p>
                            {taskToday.id !== "" ?
                                <div className='flex flex-col gap-2 items-center'>
                                    <p>Rute penugasan hari ini</p>
                                    <div className='flex flex-flow justify-center gap-2'>
                                        {taskToday.dumpIds.map((dump, index) => (
                                            <div className='p-2 rounded-md shadow-md text-xs text-white bg-basicGreen'>
                                                {RenderDumpName(dump)}
                                            </div>
                                        ))}
                                    </div>
                                    <Link to='/route'>
                                        <p className='p-2 bg-white hover:bg-orange border-2 border-orange rounded-lg shadow-md cursor-pointer font-bold text-md text-orange hover:text-white text-center'>
                                            Lihat Rute
                                        </p>
                                    </Link>
                                </div>
                                :
                                <p className='text-sm'>Belum ada penugasan untuk hari ini</p>
                            }
                            <Link to='/task'><p className='text-xs cursor-pointer text-orange font-bold'>Lihat penugasan lainnya...</p></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Janitor;
