import { Auth } from "../../types/auth";
import { SignInReq } from "../../types/authContract";
import Api from "../api";

export default async function apiSignIn(data: SignInReq) {
  const body = JSON.stringify(data);

  return await Api.post<Auth>({
    input: "/api/v1/auth/signin",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
