import React, { useRef, useEffect, useState } from 'react';

import "leaflet/dist/leaflet.css";
import L from "leaflet";
import "leaflet-routing-machine/dist/leaflet-routing-machine.css";
import "leaflet-routing-machine";
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder";

import iconDefault from '../../assets/iconDefault.png'
import useDumpList from '../../hooks/useDumpList';
import { Dump, DumpType, dumpDefault } from '../../types/dump';
import useMapSectorList from '../../hooks/useMapSectorList';
import ModalDumpInfo from '../../components/admin/map/ModalDumpInfo';
import { LogRoute, logRouteDefault } from '../../types/logRoute';
import useLogRouteList from '../../hooks/useLogRouteList';
import { dumpRoute } from '../../dummy/DummyRoute';
import ReportCollection from '../../components/janitor/ReportCollection';
import { useAppContext } from '../../contexts/DataContext';
import useAuth from '../../hooks/useAuth';
import { useLoadingContext } from '../../contexts/LoadingContext';

const RouteTask: React.FC = () => {
    const { incrementLoading, decrementLoading } = useLoadingContext()
    const [myTask, setMyTask] = useState<LogRoute[]>()
    const {data: auth} = useAuth()
    const {state}= useAppContext()

    const {
        get: getDump
    } = useDumpList()

    const {
        data: sectorList,
        get: getSector
    } = useMapSectorList()

    const {
        isLoading: isLoadingLogroute,
        get: getLogRoute
    } = useLogRouteList()

    const [selectedRoute, setSelectedRoute] = useState<LogRoute>(logRouteDefault)
    const [dumpTask, setDumpTask] = useState<Dump[]>([dumpDefault])

    // state show marker information
    const [showInfo, setShowInfo] = useState(false);
    const [selectedDump, setSelectedDump] = useState<Dump>(dumpDefault);

    // handle modal report
    const [showReport, setShowReport] = useState(false)
    const [routeReport, setRouteReport] = useState<string>("")
    const [dumpReport, setDumpReport] = useState<string>("")

    const openReport=(id: string)=>{
        setRouteReport(selectedRoute.id)
        setDumpReport(id)
        setShowReport(true)
    }
    const closeReport=()=>{
        setShowReport(false)
        setRouteReport("")
        setDumpReport("")
    }

    const mapRef = useRef<L.Map | null>(null);

    const customMarkerIcon = L.icon({
        iconUrl: iconDefault,
        iconSize: [32, 32], // Set the size of your icon
        iconAnchor: [16, 32], // Adjust the anchor point if needed
        popupAnchor: [0, -32], // Adjust the popup anchor if needed
    });

    useEffect(() => {
        isLoadingLogroute ? incrementLoading() : decrementLoading();
    }, [isLoadingLogroute])

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100,
                type: DumpType.TempDump
            }
            if (state.dumps.length === 0) await getDump(query)

            const sectorQuery = {
                limit: 100,
            }
            if (sectorList.length === 0) await getSector(sectorQuery)

            const logQuery = {
                limit: 100,
            }
            if (state.logRoutes.length === 0) await getLogRoute(logQuery)
        })()

        if (mapRef.current) {
            mapRef.current.remove();
        }

        // Inisialisasi peta
        const map = L.map("map").setView([-7.798484558411561, 110.3724409997804], 13);

        // Menambahkan tile layer dari OpenStreetMap
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "© OpenStreetMap contributors",
        }).addTo(map);

        const dataMarker: Dump[] = (state.dumps as Dump[]).filter((dump) => selectedRoute.dumpIds.includes(dump.id))
        setDumpTask(dataMarker)

        dataMarker.map((loc, index) => {
            if (loc) {
                const marker = L.marker([loc.coordinate.latitude, loc.coordinate.longitude], {
                    icon: customMarkerIcon,
                    interactive: true,
                    zIndexOffset: 1000,
                }).addTo(map);
                // Menambahkan event listener untuk klik pada setiap marker
                marker.on("click", () => {
                    // Handle klik event di sini, misalnya, tampilkan informasi atau ubah state
                    setSelectedDump(loc);
                    setShowInfo(true);
                });
            }
        });

        if (selectedRoute.id !== "") {
            const dumpLocationCoordinates: L.Routing.Waypoint[] = dataMarker
                .filter((location) => location !== null) // Filter out null values
                .map((location) => ({
                    latLng: L.latLng(
                        location!.coordinate.latitude,
                        location!.coordinate.longitude
                    ),
                    name: "Optional Location Name", // You can provide a name for the waypoint
                    // Add other properties as needed for your use case
                }));
            const osrmEndpoint = "https://router.project-osrm.org/route/v1/";

            L.Routing.control({
                waypoints: dumpLocationCoordinates,
                routeWhileDragging: false,
                router: new L.Routing.OSRMv1({
                    serviceUrl: osrmEndpoint,
                    requestParameters: {
                        overview: false,
                        alternatives: true,
                        steps: true,
                    },
                }),
            })
                .addTo(map)
                .on("routesfound", function (e) {
                    var routes = e.routes;

                    // memproses data rute sesuai kebutuhan Anda
                })
                .on("routingerror", function (e) {
                    console.error("Routing error:", e.error);
                });
        }

        mapRef.current = map;
    }, [state.dumps, selectedRoute])

    useEffect(() => {
        setMyTask(dumpRoute.filter((log) => log.driverId === auth.id))
    }, [state.logRoutes])

    return (
        <div className='flex flex-col gap-6 lg:gap-16 px-6 lg:px-24 py-24 lg:h-screen lg:text-left'>
            <div className='flex flex-col sm:flex-row gap-6 justify-between'>
                <div className='text-2xl font-bold'>
                    PETA RUTE PENGAMBILAN
                </div>
                <div>
                    <select
                        value={selectedRoute.id}
                        onChange={(e) => { setSelectedRoute(myTask?.find((task) => task.id === e.target.value) || selectedRoute) }}
                    >
                        <option value="" disabled >Pilih Route</option>
                        {myTask?.map((task) => (
                            <option value={task.id} >{task.createdAt.split("T")[0]} - Route</option>
                        ))}
                    </select>
                </div>
            </div>
            <div className='flex flex-col lg:flex-row gap-4 justify-between px-2 py-2 shadow-md rounded-lg'>
                <div id="map" style={{ height: "400px" }} className="lg:flex-1 w-full">

                </div>
                {showInfo ?
                    <div className='lg:flex-1'>
                        <div className='flex flex-col gap-2'>
                            <ModalDumpInfo
                                isOpen={showInfo}
                                onClose={() => setShowInfo((prev) => !prev)}
                                data={selectedDump}
                            />
                        </div>
                    </div>
                    :
                    <div className='lg:flex-1 items-center h-full'>
                        {selectedRoute.id === "" ?
                            <div className='flex items-center h-full justify-center'>
                                Belum ada rute yang ditampilkan
                            </div>
                            :
                            !showReport ?
                                <div className='flex flex-col gap-2'>
                                    {dumpTask.map((dump) => (
                                        <div className='w-full px-6 py-2 rounded-lg shadow-md border-2 border-basicGreen flex flex-row justify-between'>
                                            <p>{dump.name}</p>
                                            <p
                                                className='cursor-pointer text-orange font-bold'
                                                onClick={()=>openReport(dump.id)}
                                            >
                                                Lihat Laporan
                                            </p>
                                        </div>
                                    ))}
                                </div>
                                :
                                <ReportCollection
                                    isOpen={showReport}
                                    onClose={()=>closeReport()}
                                    routeId={routeReport}
                                    dumpId={dumpReport}
                                />
                        }
                    </div>
                }
            </div>
        </div>
    );
};

export default RouteTask;
