import React, { useEffect, useState } from "react";

interface DetailModalProps<T> {
    isOpen: boolean;
    onClose: () => void;
    data?: T
    onDelete: ()=>void;
}

const ModalDelete: React.FC<DetailModalProps<any>> = ({ isOpen, onClose, data, onDelete }) => {
    if (!isOpen) return null

    const handleDelete=()=>{
        onDelete()
        onClose()
    }

    return (
        <div
            className={`fixed inset-0 overflow-y-auto`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                ></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-labelledby="modal-title"
                >
                    <div className="bg-white p-4">
                        <h3 className="text-lg font-medium text-gray-900" id="modal-title">
                            Hapus <span className="font-bold text-orange">{data.name}</span> data?
                        </h3>
                    </div>
                    <div className="flex flex-col sm:flex-row justify-end gap-4 p-2">
                        <button
                            type="button"
                            className="bg-white hover:bg-blue hover:text-white text-blue border-2 border-blue py-2 px-4 rounded focus:outline-none focus:shadow-outline-orange"
                            onClick={onClose}
                        >
                            Batal
                        </button>
                        <button
                            type="button"
                            className="bg-red-500 hover:bg-red-800 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-red"
                            onClick={handleDelete}
                        >
                            Hapus
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ModalDelete;