import { MapSector } from "../../types/mapSector";
import { UpdateMapSectorReq } from "../../types/mapSectorContract";
import Api from "../api";

export default async function apiUpdateMapSector(
  id: string,
  data: UpdateMapSectorReq
) {
  const body = JSON.stringify(data);

  return await Api.patch<MapSector>({
    input: `/api/v1/map-sector/${id}`,
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
