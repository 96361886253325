import { LogCollection } from "../../types/logCollection";
import { GetLogCollectionListReqQuery } from "../../types/logCollectionContract";
import Api from "../api";

export default async function apiGetLogCollectionList(
  query?: GetLogCollectionListReqQuery
) {
  return await Api.get<LogCollection[]>({
    input: "/api/v1/log-collections",
    query,
  });
}
