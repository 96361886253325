import React, { ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

interface Option {
    value: string;
    label: string;
}
interface OptionsArray extends Array<Option> { }

interface props {
    options: OptionsArray
    pathname: string
    selectedOpt: string
    setSelectedOpt: (selected: string) => void
    searchTerm: string
    setSearchTerm: (term:string)=>void
}

const SearchBar: React.FC<props> = ({ options, pathname, selectedOpt, setSelectedOpt, searchTerm, setSearchTerm }) => {
    const navigate = useNavigate();

    // Handle select option change
    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const value = event.target.value;
        console.log(value)
        if (pathname !== "/") {
            navigate(`dataview/${value}`)
        }
        else {
            setSelectedOpt(value);
        }
    };

    return (
        <div className='bg-white w-full rounded-md p-2 flex flex-row gap-2'>
            {/* Filtered select option */}
            <select value={selectedOpt || ''} onChange={handleSelectChange}>
                <option value="" disabled>
                    Pilih opsi
                </option>
                {pathname==="/" ? 
                    <option value="">
                        Tampilkan Semua
                    </option>
                    :
                    <></>
                }
                {
                    options.map((option) => (
                        <option key={option.value} value={option.value}>
                            {option.label}
                        </option>
                    ))
                }
            </select>
            {/* Search input */}
            <input
                className='w-full'
                type="text"
                placeholder="Cari..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
        </div>
    );
};

export default SearchBar;
