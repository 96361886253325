import { useEffect, useState } from "react"
import ModalDetail from "../../../components/admin/ModalDetail"
import Pagination from "../../../components/public/Pagination"

import useSearch from "../../../hooks/useSearch"
import useLogDumpList from "../../../hooks/useLogDumpList"
import useDumpList from "../../../hooks/useDumpList"
import { LogDump } from "../../../types/logDump"
import { Dump, DumpType, dumpDefault } from "../../../types/dump"

import { useAppContext } from "../../../contexts/DataContext"

interface volumeProps {
    searchTerm: string
}

const DataVolume: React.FC<volumeProps> = ({ searchTerm }) => {
    const { state } = useAppContext()

    const {
        isLoading: isLoadingLogDumpList,
        get: getLogDumpList
    } = useLogDumpList()

    const {
        get: getDumpList
    } = useDumpList()

    useEffect(() => {
        (async () => {
            const logQuery = {
                limit: 100
            }
            if (state.logDumps.length === 0) getLogDumpList(logQuery)

            const dumpQuery = {
                limit: 100,
                type: DumpType.TempDump
            }
            if (state.dumps.length === 0) getDumpList(dumpQuery)
        })()
    }, [])

    //init pagination data
    const [currentData, setCurrentData] = useState<LogDump[]>([])

    //search handle
    const searchOptions = { keys: ['createdAt'] };
    const { data: resultData, handleSearch } = useSearch(state.logDumps, searchOptions)
    useEffect(() => {
        handleSearch(searchTerm)
    }, [searchTerm])

    const dataview = searchTerm.length === 0 ? currentData : resultData;

    const keys = [
        { title: 'Dibuat pada', key: 'createdAt'}, { title: 'TPS', key: 'dumpId' }, { title: 'Volume terukur', key: 'measuredVolume' }
    ];

    // selected data and id state
    const [selectedData, setSelectedData] = useState<any>(null);
    //modal detail handling
    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = (data: any) => {
        setSelectedData(data);
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setSelectedData(null);
        setIsModalOpen(false);
    };

    const getTPS=(id:string)=>{
        const dump: Dump=(state.dumps as Dump[]).find((item)=>item.id===id) || dumpDefault
        return dump.name
    }

    return (
        <div className="container mx-auto mt-24 flex flex-col gap-4">
            <div className='flex flex-row justify-between'>
                <div className='text-2xl font-bold'>
                    Data TPS/TPA
                </div>
            </div>
            <table className="min-w-full bg-white border border-gray-300">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b">Tanggal</th>
                        <th className="py-2 px-4 border-b">TPS</th>
                        <th className="py-2 px-4 border-b">Volume (m<sup>3</sup>)</th>
                    </tr>
                </thead>
                <tbody>
                    {dataview?.map((item) => (
                        <tr key={item.id}>
                            <td className="py-2 px-4 border-b">{item.createdAt.split("T")[0]}</td>
                            <td className="py-2 px-4 border-b">{getTPS(item.dumpId)}</td>
                            <td className="py-2 px-4 border-b">{item.measuredVolume.toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            {state.logDumps.length === 0 ?
                <div className="container mx-auto mt-8">
                    Data tidak ditemukan
                </div>
                :
                <div className="container mx-auto mt-8">
                    {searchTerm.length === 0 ? <Pagination data={state.logDumps} setCurrentData={(current: LogDump[]) => setCurrentData(current)} /> : <></>}
                </div>
            }
            <ModalDetail isOpen={isModalOpen} isLog={true} onClose={closeModal} keys={keys} data={selectedData} />
        </div>
    )
}
export default DataVolume