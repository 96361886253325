import { useEffect, useState } from "react";
import { LogCollection, logCollectionDefault } from "../types/logCollection";
import apiGetLogCollection from "../api/logCollection/apiGetLogCollection";
import apiAddLogCollection from "../api/logCollection/apiAddLogCollection";
import { AddLogCollectionReq } from "../types/logCollectionContract";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useLogCollection = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(logCollectionDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: LogCollection | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetLogCollection(id);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: AddLogCollectionReq) => {
    let resData: LogCollection | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiAddLogCollection(data);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    add,
  };
};

export default useLogCollection;
