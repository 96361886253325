import { useEffect, useState } from "react";
import { Mcu, mcuDefault } from "../types/mcu";
import apiGetMcu from "../api/mcu/apiGetMcu";
import apiUpdateMcu from "../api/mcu/apiUpdateMcu";
import { AddMcuReq, UpdateMcuReq } from "../types/mcuContract";
import apiDeleteMcu from "../api/mcu/apiDeleteMcu";
import apiAddMcu from "../api/mcu/apiAddMcu";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useMcu = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(mcuDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: Mcu | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetMcu(id);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const update = async (data: UpdateMcuReq) => {
    if (!id) throw "id is undefined";

    let resData: Mcu | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiUpdateMcu(id, data);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const remove = async () => {
    if (!id) throw "id is undefined";

    let resData: string | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      await apiDeleteMcu(id);

      resData = id
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: AddMcuReq) => {
    let resData: Mcu | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiAddMcu(data);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    update,
    remove,
    add,
  };
};

export default useMcu;
