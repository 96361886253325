import { User } from "../../types/user";
import { GetUserListReqQuery } from "../../types/userContract";
import Api from "../api";

export default async function apiGetUserList(query?: GetUserListReqQuery) {
  return await Api.get<User[]>({
    input: "/api/v1/users",
    query,
  });
}
