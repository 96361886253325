import React, { useRef, useEffect } from 'react';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconDefault from '../../assets/iconDefault.png'
import { Coordinate } from '../../types/coordinate';

interface DetailModalProps<T> {
    coordinate: {
        latitude: number
        longitude: number
    };
    isEditable: boolean
    setCoordinate?: (coor: Coordinate) => void
}

const MiniMap: React.FC<DetailModalProps<any>> = ({ coordinate, isEditable, setCoordinate }) => {
    // Ref untuk menyimpan referensi peta
    const mapRef = useRef<L.Map | null>(null);
    const customMarkerIcon = L.icon({
        iconUrl: iconDefault,
        iconSize: [32, 32], // Set the size of your icon
        iconAnchor: [16, 32], // Adjust the anchor point if needed
        popupAnchor: [0, -32], // Adjust the popup anchor if needed
    });

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.remove();
        }
        // Inisialisasi peta
        const map = L.map("map").setView(
            isEditable ? [-7.798484558411561, 110.3724409997804] : [coordinate.latitude, coordinate.longitude],
            13
        );

        const key = 'tDMeMZZ9lS5QizYGtekk'
        // Menambahkan tile layer dari OpenStreetMap
        L.tileLayer(`https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${key}`, {
            attribution: "© B-COP",
        }).addTo(map);

        // Menambahkan marker
        if (!isEditable) {
            const marker = L.marker(isEditable ? [-7.798484558411561, 110.3724409997804] : [coordinate.latitude, coordinate.longitude], {
                icon: customMarkerIcon,
                interactive: true,
                zIndexOffset: 1000,
            }).addTo(map);
        }
        else {
            // Menambahkan fungsi klik untuk menampilkan marker
            map.on('click', (e) => {
                // Hapus marker sebelumnya (jika ada)
                map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        map.removeLayer(layer);
                    }
                });

                // Tambahkan marker baru di lokasi yang diklik
                const newMarker = L.marker(e.latlng, {
                    icon: customMarkerIcon,
                    interactive: true,
                    zIndexOffset: 1000,
                }).addTo(map);

                // assign clicked location's coordinate to coordinate state
                const latitude = e.latlng.lat
                const longitude = e.latlng.lng
                const clickedCoord = { latitude, longitude }
                if (setCoordinate) { setCoordinate(clickedCoord) }
            });
        }

        // Simpan referensi peta
        mapRef.current = map;

    }, []);

    return (
        <div id="map" style={{ height: "200px" }}>

        </div>
    );
};

export default MiniMap;
