import { LogDump } from "../../types/logDump";
import { GetLogDumpListReqQuery } from "../../types/logDumpContract";
import Api from "../api";

export default async function apiGetLogDumpList(
  query?: GetLogDumpListReqQuery
) {
  return await Api.get<LogDump[]>({
    input: "/api/v1/log-dumps",
    query,
  });
}
