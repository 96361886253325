import { Dump } from "../../types/dump";
import { UpdateDumpReq } from "../../types/dumpContract";
import Api from "../api";

export default async function apiUpdateDump(id: string, data: UpdateDumpReq) {
  const body = JSON.stringify(data);

  return await Api.patch<Dump>({
    input: `/api/v1/dump/${id}`,
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
