// ModalOverlay.tsx
import React, { useEffect } from 'react';
import { Dump } from '../../../types/dump';
import useMapSectorList from '../../../hooks/useMapSectorList';

interface ModalOverlayProps {
    isOpen: boolean;
    onClose: () => void;
    data: Dump
}

const ModalDumpInfo: React.FC<ModalOverlayProps> = ({ isOpen, onClose, data }) => {
    const {
        data: mapSector,
        get: getMapSector
    } = useMapSectorList()

    useEffect(()=>{
        (async()=>{
            const query = {limit:100}
            if(mapSector.length===0)await getMapSector(query)
        })()
    })
    const getSectorName = () => {
        const sector = mapSector.find((sector) => sector.id === data.mapSectorId);
        return sector ? sector.name : 'N/A';
      };

    return (
        <div className="absolute right-5 z-[1000] top-1/2 -translate-y-1/2 max-w-20 flex flex-col justify-center bg-white rounded-lg text-left shadow-xl transform transition-all">
            <div className="flex gap-x-2 justify-between items-center border-b p-4">
                <h2 className="text-xl font-bold">{data.name || 'No Name'}</h2>
                <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                    Close
                </button>
            </div>
            <div className="p-4">
                <p>
                    <strong>Map Sector:</strong> {getSectorName()}
                </p>
                <p>
                    <strong>Coordinate:</strong> {data.coordinate ? `${data.coordinate.latitude}, ${data.coordinate.longitude}` : 'N/A'}
                </p>
                <p>
                    <strong>Type:</strong> {data.type || 'N/A'}
                </p>
                <p>
                    <strong>Capacity:</strong> {data.capacity || 'N/A'} m<sup>3</sup>
                </p>
            </div>
        </div>
    );
};

export default ModalDumpInfo;
