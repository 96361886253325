import { ReactNode, createContext, useContext, useEffect, useState } from "react";
import Loading from "../components/public/Loading";

export const LoadingContext = createContext<
  { incrementLoading: () => void; decrementLoading: () => void } | undefined
>(undefined);

const LoadingProvider = ({ children }: { children: ReactNode }) => {
  const [loadingCounter, setLoadingCounter] = useState(0);

  const incrementLoading = () => {
    setLoadingCounter((prev) => prev + 1);
  };

  const decrementLoading = () => {
    setLoadingCounter((prev) => prev > 0 ? prev - 1 : prev);
  };

  return (
    <LoadingContext.Provider value={{ incrementLoading, decrementLoading }}>
      {children}
      {loadingCounter > 0 ? <Loading /> : <></>}
    </LoadingContext.Provider>
  );
};

const useLoadingContext = () => {
  const context = useContext(LoadingContext);
  if (!context) {
    throw new Error("useLoadingContext must be used within a LoadingProvider");
  }
  return context;
};

export { LoadingProvider, useLoadingContext };
