import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import Pagination from '../../../components/public/Pagination';
import useSearch from '../../../hooks/useSearch';
import { LogCollection } from '../../../types/logCollection';
import useLogCollectionList from '../../../hooks/useLogCollectionList';
import { useAppContext } from '../../../contexts/DataContext';

interface reportProps {
  searchTerm: string
}

const DataCollections: React.FC<reportProps> = ({ searchTerm }) => {
  const {state} = useAppContext()
  const {
    get: getCollectionsList,
  } = useLogCollectionList();

  useEffect(() => {
    (async () => {
      const query = {
        limit: 100,
      };
      if (state.logCollections.length === 0) await getCollectionsList(query);
    })()
  }, [])

  const [currentData, setCurrentData] = useState<LogCollection[]>([])

  //search handle
  const searchOptions = { keys: ['type'] };
  const { data: resultData, handleSearch } = useSearch(state.logCollections, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const dataview = searchTerm.length === 0 ? currentData : resultData;

  const handleViewClick = () => {
    // Add logic for handling view action
    console.log('View Clicked');
  };

  const handleDeleteClick = () => {
    // Add logic for handling delete action
    console.log('Delete Clicked');
  };

  return (
    <div className="container mx-auto mt-24">
      ini data pengambilan
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Tanggal</th>
            <th className="py-2 px-4 border-b">Rute</th>
            <th className="py-2 px-4 border-b">Lokasi</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Volume</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.createdAt}</td>
              <td className="py-2 px-4 border-b">{item.routeId}</td>
              <td className="py-2 px-4 border-b">{item.dumpId}</td>
              <td className="py-2 px-4 border-b">{item.status}</td>
              <td className="py-2 px-4 border-b">{item.volume}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow 
                  view={["DETAIL"]}
                  onViewClick={handleViewClick} 
                  onDeleteClick={handleDeleteClick} 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.logCollections.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={state.logCollections} setCurrentData={(current: LogCollection[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
    </div>
  );
};

export default DataCollections;
