import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../public/Searchbar';
import { useNavigate } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faUser } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../../hooks/useAuth';
import { useAppContext } from '../../contexts/DataContext';

interface DataItem {
    [key: string]: any; // Representasi umum untuk atribut JSON apapun
}

interface Option {
    value: string;
    label: string;
}
interface OptionsArray extends Array<Option> { }

interface NavbarProps {
    links: { path: string; label: string }[];
    pathname: string;
    options: OptionsArray;
    selectedOpt: string;
    setSelectedOpt: (selected: string) => void;
    searchTerm: string
    setSearchTerm: (term: string) => void
    role: string
}

const AdmNavbar: React.FC<NavbarProps> = ({ links, pathname, options, selectedOpt, setSelectedOpt, searchTerm, setSearchTerm, role }) => {
    const { data: auth, signOut } = useAuth();
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [showProfile, setShowProfile] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const navigate = useNavigate();

    const { state } = useAppContext()
    const [notification, setNotification] = useState<DataItem[]>([])

    useEffect(() => {
        setNotification(state.Notif)
    }, [state.Notif])

    useEffect(() => {
        if (showProfile) {
            window.addEventListener("click", unshownProfile);
            return () => {
                unshownProfile()
                window.removeEventListener("click", unshownProfile);
            };
        } else if (isMobileMenuOpen) {
            window.addEventListener("click", closeMobileMenu);
            return () => {
                closeMobileMenu()
                window.removeEventListener("click", closeMobileMenu);
            };
        }
    }, [showProfile, isMobileMenuOpen])

    const handleProfile = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        closeMobileMenu();
        setShowProfile(!showProfile);
    }

    const handleNotification = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        closeMobileMenu();
        setShowNotification(!showNotification);
    }

    const toggleMobileMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        unshownProfile();
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const onClickLogout = () => {
        signOut();
        navigate('/login', { replace: true });
        window.location.reload();
    }

    const unshownProfile = () => {
        setShowProfile(false);
    }

    const closeMobileMenu = () => {
        setMobileMenuOpen(false);
    }

    return (
        <nav className="flex justify-center sticky-nav px-8 pt-4">
            <div className="h-14 flex items-center gap-x-4 bg-basicGreen py-2 shadow-lg rounded-xl w-full px-4 sm:px-24">
                <div className="text-white font-semibold text-md w-full text-left">
                    <Link to='/'>B-COP</Link>
                </div>
                {/* Burger menu icon for mobile */}
                <div className="lg:hidden cursor-pointer" onClick={toggleMobileMenu}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </div>
                {role === "ADMIN" ?
                    <SearchBar
                        options={options}
                        pathname={pathname}
                        selectedOpt={selectedOpt}
                        setSelectedOpt={setSelectedOpt}
                        searchTerm={searchTerm}
                        setSearchTerm={(term: string) => setSearchTerm(term)}
                    />
                    :
                    <></>
                }
                <ul className="hidden lg:flex space-x-4 gap-2 items-center">
                    {links.map((link, index) => (
                        <li key={index} className="text-white hover:text-gray-300">
                            <Link to={link.path}>{link.label}</Link>
                        </li>
                    ))}
                </ul>
                <div className="relative" onClick={handleNotification}>
                    <FontAwesomeIcon icon={faBell} className="text-white ml-2 cursor-pointer" />
                    {notification.length > 0 && (
                        <div className="absolute right-0 top-0 bg-red-500 rounded-full w-5 h-5 flex items-center justify-center text-white text-xs">
                            {notification.length}
                        </div>
                    )}
                    {showNotification && (
                        <div onClick={(e) => e.stopPropagation()} className="absolute right-0 min-w-[10rem] max-w-[20rem] mt-6 bg-white text-left p-2 shadow rounded flex flex-col gap-2">
                            {notification.length !==0 ? 
                                notification?.map((item) => (
                                    <div>
                                        <p>Laporan Sampah Masuk</p>
                                    </div>
                                ))
                                :
                                <p>Belum ada notif masuk</p>
                            }
                        </div>
                    )}
                </div>
                <div className="relative" onClick={handleProfile}>
                    <FontAwesomeIcon icon={faUser} className="text-white ml-2 cursor-pointer" />
                    {showProfile && (
                        <div onClick={(e) => e.stopPropagation()} className="absolute right-0 min-w-[10rem] max-w-[20rem] mt-6 bg-white text-left p-2 shadow rounded">
                            <p className="mb-2 truncate font-bold">{auth.name}</p>
                            <p className="mb-2">({auth.role})</p>
                            <div className="h-[1px] bg-black mb-2" />
                            <button onClick={onClickLogout} className="block mx-auto bg-red-500 text-white px-2 py-1 rounded">
                                Logout
                            </button>
                        </div>
                    )}
                </div>

                {/* Mobile menu */}
                {isMobileMenuOpen && (
                    <div className="lg:hidden absolute top-20 left-0 w-full px-8">
                        <div onClick={(e) => e.stopPropagation()} className="bg-basicGreen p-4 rounded-xl">
                            <ul className="flex flex-col space-y-2">
                                {links.map((link, index) => (
                                    <li key={index} className="text-white hover:text-gray-300">
                                        <Link onClick={closeMobileMenu} to={link.path}>{link.label}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default AdmNavbar;
