import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import useDumpList from '../../hooks/useDumpList';
import { Dump, DumpType } from '../../types/dump';
import useLogReport from '../../hooks/useLogReport';
import iconDefault from '../../assets/iconDefault.png'
import { Coordinate } from '../../types/coordinate';
import useMedia from '../../hooks/useMedia';
import { useDisplayContext } from '../../contexts/DisplayContext';
import { useAppContext } from '../../contexts/DataContext';
import { useLoadingContext } from '../../contexts/LoadingContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocation } from '@fortawesome/free-solid-svg-icons';

const Report: React.FC = () => {
    const { incrementLoading, decrementLoading } = useLoadingContext()
    const { notify } = useDisplayContext();
    const { state } = useAppContext();
    const {
        get: getDumpList,
    } = useDumpList();

    const {
        isLoading: isLoadingReport,
        add: addReport,
    } = useLogReport();

    const {
        isLoading: isLoadingMedia,
        add: addMedia
    } = useMedia();

    const [reportType, setReportType] = useState<string>('');
    const [description, setDescription] = useState<string>('');
    const [tps, setTPS] = useState<string>()
    const [coordinate, setCoordinate] = useState<Coordinate>({
        latitude: 0,
        longitude: 0
    })
    const [email, setEmail] = useState<string>('')
    const [selectedFile, setSelectedFile] = useState<File>();

    const [current, setCurrent] = useState(false)

    const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            const selected = files[0];
            setSelectedFile(selected);
        }
    };

    const mapRef = useRef<L.Map | null>(null);

    const customMarkerIcon = L.icon({
        iconUrl: iconDefault,
        iconSize: [32, 32], // Set the size of your icon
        iconAnchor: [16, 32], // Adjust the anchor point if needed
        popupAnchor: [0, -32], // Adjust the popup anchor if needed
    });

    useEffect(() => {
        isLoadingMedia ? incrementLoading() : decrementLoading();
    }, [isLoadingMedia])

    useEffect(() => {
        isLoadingReport ? incrementLoading() : decrementLoading();
    }, [isLoadingReport])

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100,
                type: DumpType.TempDump
            }
            if (state.dumps.length === 0) await getDumpList(query)
        })()
    }, [])

    const handleGetCurrentLocation = () => {
        if (mapRef.current) {
            const map = mapRef.current
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    const newPosition: [number, number] = [latitude, longitude];

                    // Set the map view to the new position
                    map.setView(newPosition, 15);

                    map.eachLayer((layer) => {
                        if (layer instanceof L.Marker) {
                            map.removeLayer(layer);
                        }
                    });

                    // Add a marker for the current location
                    const newMarker = L.marker(newPosition, {
                        icon: customMarkerIcon,
                        interactive: true,
                        zIndexOffset: 1000,
                    }).addTo(map);
                    const clickedCoord = { latitude, longitude };
                    if (setCoordinate) {
                        setCoordinate(clickedCoord);
                    }
                },
                (error) => {
                    console.error(`Error getting current location: ${error.message}`);
                }
            );
        }
    };

    useEffect(() => {
        // Inisialisasi peta hanya jika opsi terpilih adalah 'Sampah Berserak'
        if (reportType === 'Sampah Berserakan') {
            // Hancurkan peta jika sudah ada
            if (mapRef.current) {
                mapRef.current.remove();
            }

            // Buat peta baru
            const map = L.map('map').setView([-7.798484558411561, 110.3724409997804], 13);

            const key = 'tDMeMZZ9lS5QizYGtekk'
            // Menambahkan tile layer dari OpenStreetMap
            L.tileLayer(`https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${key}`, {
                attribution: "© B-COP",
            }).addTo(map);

            map.on('click', (e) => {
                // Hapus marker sebelumnya (jika ada)
                map.eachLayer((layer) => {
                    if (layer instanceof L.Marker) {
                        map.removeLayer(layer);
                    }
                });

                // Tambahkan marker baru di lokasi yang diklik
                const newMarker = L.marker(e.latlng, {
                    icon: customMarkerIcon,
                    interactive: true,
                    zIndexOffset: 1000,
                }).addTo(map);

                // assign clicked location's coordinate to coordinate state
                const latitude = e.latlng.lat;
                const longitude = e.latlng.lng;
                const clickedCoord = { latitude, longitude };
                if (setCoordinate) {
                    setCoordinate(clickedCoord);
                }
            });

            mapRef.current = map;
        }
    }, [reportType, current]);

    const handleSubmit = async () => {

        if (selectedFile === undefined) return;

        const { resData: resAddMedia } = await addMedia({ file: selectedFile })
        if (!resAddMedia?.id) return

        await addReport({
            type: reportType,
            reporterName: email,
            mediaId: resAddMedia.id,
            dumpId: tps,
            coordinate: coordinate,
            description: description,
            status: 'Reported'
        })
        if (!isLoadingReport) {
            setReportType("")
            setDescription("")
            setTPS("")
            setCoordinate({ latitude: 0, longitude: 0 })
            setEmail("")
            setSelectedFile(undefined)
            notify.info("Laporan Berhasil Dikirim")
        }
    }

    return (
        <div className="flex items-center justify-center mt-24">
            <div className="w-full sm:w-1/2 bg-white p-8 rounded-xl border-2 border-orange shadow-xl text-left overflow-y-scroll">
                <h2 className="text-2xl mb-6 font-semibold">Form Laporan</h2>

                {/* Jenis Laporan */}
                <div className="mb-4">
                    <label htmlFor="reportType" className="block text-sm font-medium text-gray-600">Jenis Laporan</label>
                    <select
                        id="reportType"
                        className="mt-1 p-2 w-full border rounded"
                        value={reportType}
                        onChange={(e) => setReportType(e.target.value)}
                        required
                    >
                        <option value="" disabled>Pilih Jenis Laporan</option>
                        <option value="Kondisi TPS">Kondisi TPS</option>
                        <option value="Sampah Berserakan">Sampah Berserakan</option>
                    </select>
                </div>

                {/* Lokasi TPS (hanya muncul jika Jenis Laporan adalah TPS) */}
                {reportType === 'Kondisi TPS' && (
                    <div className="mb-4">
                        <label htmlFor="location" className="block text-sm font-medium text-gray-600">Lokasi TPS</label>
                        <select
                            id="location"
                            className="mt-1 p-2 w-full border rounded"
                            value={tps}
                            onChange={(e) => {
                                setTPS(e.target.value)
                                const coord = (state.dumps as Dump[]).find((dump) => dump.id === e.target.value)
                                setCoordinate(coord ? { latitude: coord?.coordinate.latitude, longitude: coord?.coordinate.longitude } : { latitude: 0, longitude: 0 })
                            }}
                            required
                        >
                            <option value="" disabled>Pilih Lokasi TPS</option>
                            {(state.dumps as Dump[]).map((dump) => (
                                <option value={dump.id}>{dump.name}</option>
                            ))}
                        </select>
                    </div>
                )}
                <div className={reportType === 'Sampah Berserakan' ? 'block' : 'hidden'}>
                    <label htmlFor="location" className="block text-sm font-medium text-gray-600">Lokasi Sampah</label>
                    <div className="">
                        <div id="map" style={{ height: '240px' }}>
                            <div
                                className='z-[500] h-min absolute bottom-10 right-5 bg-orange p-2 rounded-md shadow-lg text-white font-bold text-sm cursor-pointer'
                                onClick={handleGetCurrentLocation}
                            >
                                <FontAwesomeIcon icon={faLocation} className="text-white" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Deskripsi */}
                <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-600">Deskripsi</label>
                    <textarea
                        id="description"
                        className="mt-1 p-2 w-full border rounded"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                </div>

                {/* Foto */}
                <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-600">Foto Evidence</label>
                    <input
                        type="file"
                        accept="image/*" // Restrict to image files
                        onChange={handleFileChange}
                        className="border p-2 w-full"
                    />

                    {selectedFile && (
                        <div className="mt-4">
                            <p className="font-semibold">Selected File: {selectedFile.name}</p>
                            <img
                                src={URL.createObjectURL(selectedFile)}
                                alt="Selected"
                                className="max-w-full max-h-200"
                            />
                        </div>
                    )}
                </div>

                {/* Kontak */}
                <div className="mb-4">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-600">
                        Email
                        <p className='text-xs font-light'>*jika ingin mendapatkan feedback</p>
                    </label>
                    <input
                        type='email'
                        id="email"
                        className="mt-1 p-2 w-full border rounded"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </div>

                <div className='flex justify-end'>
                    <button
                        className="bg-orange text-white py-2 px-4 rounded hover:bg-blue-600"
                        onClick={handleSubmit}
                    >
                        Kirim Laporan
                    </button>
                </div>
            </div>
        </div>
    );
}

export default Report;
