import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import Pagination from '../../../components/public/Pagination';
import useSearch from '../../../hooks/useSearch';
import useLogReportList from '../../../hooks/useLogReportList';
import { LogReport } from '../../../types/logReport';
import ModalDetail from '../../../components/admin/ModalDetail';
import { useAppContext } from '../../../contexts/DataContext';

interface reportProps {
  searchTerm: string
}

const DataReport: React.FC<reportProps> = ({ searchTerm }) => {
  const {state} = useAppContext()
  const {
    get: getReportList,
  } = useLogReportList();

  useEffect(() => {
    (async () => {
      const query = {
        limit: 100,
      };
      if (state.logReports.length === 0) await getReportList(query);
    })()
  }, [])

  const [currentData, setCurrentData] = useState<LogReport[]>([])

  //search handle
  const searchOptions = { keys: ['type'] };
  const { data: resultData, handleSearch } = useSearch(state.logReports, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const dataview = searchTerm.length === 0 ? currentData : resultData;

  const keys = [
    { title: 'Dibuat pada', key: 'createdAt' },
    { title: 'Tipe', key: 'type' },
    { title: 'Email pelapor', key: 'reporterEmail' },
    { title: 'Deskripsi', key: 'description' },
    { title: 'Status', key: 'status' }
  ];
  const [selectedData, setSelectedData] = useState<any>(null);

  //modal detail handling
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (data: any) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedData(null);
    setIsModalOpen(false);
  };

  const handleDeleteClick = () => {
    // Add logic for handling delete action
    console.log('Delete Clicked');
  };

  return (
    <div className="container mx-auto mt-24 flex flex-col gap-4">
      <div className='flex flex-row justify-between'>
        <div className='text-2xl font-bold'>
          Log Laporan
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Tanggal</th>
            <th className="py-2 px-4 border-b">Tipe Laporan</th>
            <th className="py-2 px-4 border-b">Pelapor</th>
            <th className="py-2 px-4 border-b">Status</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.createdAt.split("T")[0]}</td>
              <td className="py-2 px-4 border-b">{item.type}</td>
              <td className="py-2 px-4 border-b">{item.reporterName}</td>
              <td className="py-2 px-4 border-b">{item.status}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow
                  view={["DETAIL"]}
                  onViewClick={() => openModal(item)}
                  onDeleteClick={handleDeleteClick}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.logReports.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={state.logReports} setCurrentData={(current: LogReport[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
      <ModalDetail isOpen={isModalOpen} isLog={true} onClose={closeModal} keys={keys} data={selectedData} />
    </div>
  );
};

export default DataReport;
