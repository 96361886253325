import { MapSector } from "../../types/mapSector";
import { GetMapSectorListReqQuery } from "../../types/mapSectorContract";
import Api from "../api";

export default async function apiGetMapSectorList(
  query?: GetMapSectorListReqQuery
) {
  return await Api.get<MapSector[]>({
    input: "/api/v1/map-sectors",
    query,
  });
}
