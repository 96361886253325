import { Mcu } from "../../types/mcu";
import { AddMcuReq } from "../../types/mcuContract";
import Api from "../api";

export default async function apiAddMcu(data: AddMcuReq) {
  const body = JSON.stringify(data);

  return await Api.post<Mcu>({
    input: "/api/v1/mcu",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
