import React, { useState } from 'react';
import { DumpType } from '../../types/dump';
import { AddLogRouteReq } from '../../types/logRouteContract';
import { GetRouteRes } from '../../types/routeContract';
import useLogRoute from '../../hooks/useLogRoute';
import { useDisplayContext } from '../../contexts/DisplayContext';
import { useAppContext } from '../../contexts/DataContext';

interface DataItem {
    [key: DumpType | number | string]: any; // Representasi umum untuk atribut JSON apapun
}

interface taskProps {
    item: GetRouteRes
    index: number
    driver: DataItem[]
    selectedDrivers: string[];
    onSelectDriver: (index: number, driverId: string) => void;
}
const ModalTaskSubmit: React.FC<taskProps> = ({ item, index, driver, selectedDrivers, onSelectDriver }) => {
    const { notify } = useDisplayContext()
    const { add: addLogRoute } = useLogRoute()
    const [newLogRoute, setNewLogRoute] = useState<AddLogRouteReq>({
        driverId: "",
        truckId: item.truck.id,
        dumpIds: item.dumps.map(d => d.id)
    })

    const handleDriverChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedDriverId = e.target.value;
        setNewLogRoute((prevData: any) => ({ ...prevData, driverId: selectedDriverId }));
        onSelectDriver(index, selectedDriverId);
    };

    const submitTask = async () => {
        const { error } = await addLogRoute(newLogRoute);
        if (error) {
            notify.error(error.message);
            return;
        }
    }

    return (
        <div className='w-full shadow-md rounded-lg border-2 border-basicGreen px-4 py-1 flex flex-col gap-4'>
            <div className='flex flex-row justify-between items-center text-sm'>
                <div>
                    Route {index+1}
                </div>
                <div>
                    Truk {item.truck.licensePlate}
                </div>
                <div>
                    <select
                        value={newLogRoute.driverId}
                        onChange={handleDriverChange}
                    >
                        <option value="" disabled>Pilih Driver</option>
                        {driver?.map((driver) => (
                            <option
                                key={driver.id} // Make sure to add a unique key for each option
                                value={driver.id}
                                disabled={selectedDrivers.includes(driver.id)}
                            >
                                {driver.name}
                            </option>
                        ))}
                    </select>
                </div>
                {newLogRoute.driverId !== "" ?
                    <div onClick={submitTask}
                        className='rounded-lg px-6 py-1 bg-orange text-white cursor-pointer'>
                        Submit
                    </div>
                    :
                    <div className='text-sm text-gray-500'>...Choose Driver</div>
                }
            </div>
            <div>

            </div>
        </div>
    );
};

export default ModalTaskSubmit;
