import React, { useState } from 'react';
import { Dump } from '../../../types/dump';
import { useDisplayContext } from '../../../contexts/DisplayContext';
import { ErrorI } from '../../../types/api';

interface TruckModalProps {
    isOpen: boolean;
    getRoute: (selectedTPAId: string) => Promise<{ error?: ErrorI }>
    onClose: () => void;
    onSubmit: () => void
    dumpListData: Dump[]
}

const ModalAddRoute: React.FC<TruckModalProps> = ({ isOpen, getRoute, onClose, onSubmit, dumpListData }) => {
    const { notify } = useDisplayContext()
    const [selectedTPAId, setSelectedTPAId] = useState<string>('');

    const handleSave = async () => {
        // Lakukan sesuatu dengan nilai yang dipilih
        const { error } = await getRoute(selectedTPAId);
        if (error) {
            notify.error(error.message);
            return;
        }

        // Tutup modal setelah menyimpan
        onClose();
        onSubmit();
    };

    if (!isOpen) return null

    return (
        <div
            className={`fixed inset-0 overflow-y-auto z-[1000]`}
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div
                    className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                ></div>

                <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                    &#8203;
                </span>

                <div
                    className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-labelledby="modal-title"
                >
                    <div className="bg-white p-4">
                        <h3 className="text-lg font-medium text-gray-900" id="modal-title">
                            Buat Rute Baru
                        </h3>
                    </div>
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="sm:flex sm:items-start">
                            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                <div className="flex flex-col gap-2 text-sm w-full">
                                    <div>
                                        <label>Pilih TPA:</label>
                                        <select
                                            onChange={(e) => setSelectedTPAId(e.target.value)}
                                            className='w-full border-2 rounded-md py-2'>
                                            {/* Pilihan TPA disesuaikan dengan kebutuhan Anda */}
                                            <option></option>
                                            {dumpListData?.map((item) => (
                                                <option value={item.id}>{item.name}</option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bg-white px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse sm:justify-between">
                        <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-basicGreen text-base font-medium text-white hover:bg-green-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:ml-3 sm:w-auto sm:text-sm"
                            onClick={handleSave}
                        >
                            Buat Rute
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalAddRoute;
