import { useState, useEffect } from 'react';

interface DataItem {
  [key: string]: any; // Representasi umum untuk atribut JSON apapun
}

interface SearchOptions {
  keys: string[]; // Atribut-atribut JSON yang ingin dijadikan kriteria pencarian
}

const useSearch = (initialData: DataItem[], options: SearchOptions) => {
  const [data, setData] = useState<DataItem[]>(initialData);
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    const search = () => {
      const filteredData = initialData.filter((item) =>
        options.keys.some((key) =>
          item[key].toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setData(filteredData);
    };

    search();
  }, [searchTerm]);

  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return { data, handleSearch };
};

export default useSearch;
