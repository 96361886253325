export interface Truck {
  id: string;
  createdAt: string;
  updatedAt: string;
  mapSectorIds: string[];
  licensePlate: string;
  type: string;
  capacity: number;
  fuelConsumption: number;
  isActive: boolean;
}

export const truckDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  mapSectorIds: [],
  licensePlate: "",
  type: "",
  capacity: 0,
  fuelConsumption: 0,
  isActive: false,
} as Truck;
