import React, { createContext, useReducer, useContext, ReactNode } from 'react';
import { Dump } from '../types/dump';
import { Truck } from '../types/truck';
import { LogReport } from '../types/logReport';
import { User } from '../types/user';
import { LogRoute } from '../types/logRoute';
import { LogCollection } from '../types/logCollection';
import { LogDump } from '../types/logDump';
import { Mcu } from '../types/mcu';

// Define a generic type for your data
interface Entity {
  // Add other common properties
  id?: string
}

// Define specific types for different entities
interface dumps extends Entity {
  // Add dump-specific properties
  data: Dump
}

interface trucks extends Entity {
  // Add truck-specific properties
  data: Truck
}

interface drivers extends Entity {
  data: User
}

interface logReports extends Entity {
  // Add log-specific properties
  data: LogReport
}

interface logRoutes extends Entity{
  data: LogRoute
}

interface todayRoutes extends Entity{
  data: LogRoute
}

interface logCollections extends Entity{
  data: LogCollection
}

interface logDumps extends Entity{
  data: LogDump
}

interface MCUs extends Entity{
  data: Mcu
}

interface Notif extends Entity{
  [key: string]: any
}

// Create a union type for all possible entities
type EntityType = 'dumps' | 'trucks' | 'drivers' | 'logReports' |'logRoutes' | 'logCollections' | 'logDumps' | 'MCUs' | 'Notif' | 'todayRoutes';

// Define the state using a generic type
interface AppState {
  [key: string]: Entity[];
}

// Actions
type Action =
  | { type: 'ADD'; payload: { type: EntityType; data: Entity } }
  | { type: 'GET'; payload: { type: EntityType; data: Entity[] } }
  | { type: 'UPDATE'; payload: { type: EntityType; data: Entity } }
  | { type: 'DELETE'; payload: { type: EntityType; id: string } };

// Reducer function
export const appReducer = (state: AppState, action: Action): AppState => {
  switch (action.type) {
    case 'GET':
      return {
        ...state,
        [action.payload.type]: action.payload.data as Entity[],
      };
      case 'ADD':
        return {
          ...state,
          [action.payload.type]: [action.payload.data, ...state[action.payload.type]],
        };
    case 'UPDATE':
      return {
        ...state,
        [action.payload.type]: state[action.payload.type].map((item: Entity) =>
          item.id === action.payload.data.id ? action.payload.data : item
        ),
      };
    case 'DELETE':
      return {
        ...state,
        [action.payload.type]: state[action.payload.type].filter(
          (item: Entity) => item.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};

// Context
interface AppContextProps {
  children: ReactNode;
}

export const AppContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<Action>;
} | undefined>(undefined)

const AppProvider = ({ children }: AppContextProps) => {
  const [state, dispatch] = useReducer(appReducer, {
    dumps: [] as dumps[],
    trucks: [] as trucks[],
    drivers: [] as drivers[],
    logReports: [] as logReports[],
    logRoutes: [] as logRoutes[],
    logCollections: [] as logCollections[],
    logDumps: [] as logDumps[],
    MCUs: [] as MCUs[],
    Notif: [] as Notif[],
    todayRoutes: [] as todayRoutes[]
  });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};

export { AppProvider, useAppContext };
