export interface Media {
  id: string;
  createdAt: string;
  updatedAt: string;
  filename: string;
  header: string;
  size: number;
}

export const mediaDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  filename: "",
  header: "",
  size: 0,
} as Media;
