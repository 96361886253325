import { useEffect, useState } from "react";
import { User, userDefault } from "../types/user";
import apiGetUser from "../api/user/apiGetUser";
import { UpdateUserReq } from "../types/userContract";
import apiUpdateUser from "../api/user/apiUpdateUser";
import apiDeleteUser from "../api/user/apiDeleteUser";
import { useAppContext } from "../contexts/DataContext";
import { useDisplayContext } from "../contexts/DisplayContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useUser = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { notify } = useDisplayContext();
  const { dispatch } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(userDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: User | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetUser(id);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const update = async (data: UpdateUserReq) => {
    if (!id) throw "id is undefined";

    let resData: User | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiUpdateUser(id, data);

      setData(res.json.data);

      dispatch({
        type: "UPDATE",
        payload: {
          type: "drivers",
          data: res.json.data,
        },
      });

      resData = res.json.data;

      notify.info(`Berhasil memperbaharui user ${res.json.data.name}`)
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal memperbaharui data user")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const remove = async () => {
    if (!id) throw "id is undefined";

    let resData: string | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      await apiDeleteUser(id);

      dispatch({
        type: "DELETE",
        payload: {
          type: "trucks",
          id: id,
        },
      });

      resData = id;

      notify.info("Berhasil menghapus data user")
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal menghapus data user")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    update,
    remove,
  };
};

export default useUser;
