import React, { ReactNode, createContext, useContext, useState } from "react";
import { Auth, authDefault } from "../types/auth";

export const AuthContext = createContext<
  | {
      auth: Auth;
      setAuth: React.Dispatch<Auth>;
    }
  | undefined
>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [auth, setAuth] = useState(authDefault);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuthContext must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuthContext };
