import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope, faSitemap, faComputer } from '@fortawesome/free-solid-svg-icons';
import logoDLH from '../../assets/logoDLH.png'
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconDefault from '../../assets/iconDefault.png'

const Contact: React.FC = () => {
    const mapRef = useRef<L.Map | null>(null);
    const customMarkerIcon = L.icon({
        iconUrl: iconDefault,
        iconSize: [32, 32], // Set the size of your icon
        iconAnchor: [16, 32], // Adjust the anchor point if needed
        popupAnchor: [0, -32], // Adjust the popup anchor if needed
    });

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.remove();
        }

        const map = L.map("map").setView([-7.787060794831851, 110.38803446137973], 13);

        // Menambahkan tile layer dari OpenStreetMap
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            attribution: "© OpenStreetMap contributors",
        }).addTo(map);

        const marker = L.marker([-7.787053356792169, 110.38803821501374], {
            icon: customMarkerIcon,
            interactive: true,
            zIndexOffset: 1000,
        }).addTo(map);

        mapRef.current = map;
    }, [])

    const handleLinkClick = () => {
        window.open("https://lingkunganhidup.jogjakota.go.id/", '_blank');
    };
    return (
        <div className="flex flex-col lg:flex-row gap-4 lg:justify-between container mx-auto p-8 px-12 lg:px-24 py-24 items-center lg:h-screen">
            <div className='flex flex-col lg:flex-row gap-6 items-center'>
                <div>
                    <img
                        alt='logo DLH'
                        src={logoDLH}
                        className='w-24'
                    />
                </div>
                <div className='text-center lg:text-left font-bold text-lg lg:text-2xl'>
                    DINAS LINGKUNGAN HIDUP
                    <p>KOTA YOGYAKARTA</p>
                </div>
            </div>
            <div className="max-w-md bg-white rounded-md shadow-md overflow-hidden">
                <div id="map" style={{ height: "200px" }} className="flex-1 w-full">

                </div>
                <div className="p-6">
                    <h2 className="text-2xl font-bold mb-4">Hubungi Kami</h2>

                    {/* Field Telepon */}
                    <div className="flex items-center mb-4">
                        <FontAwesomeIcon icon={faPhone} className="text-gray-500 mr-2" />
                        <span className="text-gray-700">Nomor Telepon:</span>
                        <span className="ml-2">+62 274 588518</span>
                    </div>

                    {/* Field Email */}
                    <div className="flex items-center">
                        <FontAwesomeIcon icon={faEnvelope} className="text-gray-500 mr-2" />
                        <span className="text-gray-700">Email:</span>
                        <span
                            className="ml-2">lingkunganhidup@jogjakota.go.id</span>
                    </div>

                    {/* Field Web */}
                    <div className='flex flex-col mt-12'>
                        <p className='text-sm text-left'>Kunjungi laman kami</p>
                        <div className="flex items-center">
                            <FontAwesomeIcon icon={faComputer} className="text-gray-500 mr-2" />
                            <span
                                className="ml-2 text-basicGreen cursor-pointer"
                                onClick={handleLinkClick}
                            >
                                https://lingkunganhidup.jogjakota.go.id/
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
