import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import Pagination from '../../../components/public/Pagination';
import ModalAdd from '../../../components/admin/ModalAddData';
import ModalDetail from '../../../components/admin/ModalDetail';
import { Truck } from '../../../types/truck';
import useSearch from '../../../hooks/useSearch';
import { AddTruckReq } from '../../../types/truckContract';
import useTruck from '../../../hooks/useTruck';
import useTruckList from '../../../hooks/useTruckList';
import { useAppContext } from '../../../contexts/DataContext';
import ModalDelete from '../../../components/admin/ModalDelete';

interface truckProps {
  searchTerm: string
}

const DataTruck: React.FC<truckProps> = ({ searchTerm }) => {
  const [selectedData, setSelectedData] = useState<any>(null);

  const {
    get: getTruckList,
  } = useTruckList();

  const {
    add: addTruck,
    update: updatetruck,
    remove: removeTruck
  } = useTruck(selectedData?.id);

  const { state } = useAppContext();

  useEffect(() => {
    (async () => {
      const query = {
        limit: 100,
      };
      if (state.trucks.length === 0) await getTruckList(query);
    })()
  }, [])

  const [currentData, setCurrentData] = useState<Truck[]>([])

  //search handle
  const searchOptions = { keys: ['type'] };
  const { data: resultData, handleSearch } = useSearch(state.trucks, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const dataview = searchTerm.length === 0 ? currentData : resultData;

  const keys = [
    { title: 'Pelat nomor', key: 'licensePlate'},
    { title: 'Tipe', key: 'type'},
    { title: 'Kapasitas', key: 'capacity'},
    { title: 'Konsumsi BBM', key: 'fuelConsumption'},
    { title: 'Aktif', key: 'isActive'}
  ];

  //modal detail handling
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = (data: any) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedData(null);
    setIsModalOpen(false);
  };

  //modal add handling
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [addData, setAddData] = useState<AddTruckReq>({
    licensePlate: "",
    type: "",
    capacity: 0,
    fuelConsumption: 0,
    isActive: true
  })
  const openAdd = () => {
    setIsAddOpen(true)
  }
  const closeAdd = () => {
    setIsAddOpen(false)
  }

  // modal delete handling
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const openDelete = (data: any) => {
    setSelectedData(data);
    setIsDeleteOpen(true)
  }
  const closeDelete = () => {
    setIsDeleteOpen(false)
  }

  return (
    <div className="container mx-auto mt-24 flex flex-col gap-4">
      <div className='flex flex-row justify-between'>
        <div className='text-2xl font-bold'>
          Data Truk
        </div>
        <div>
          <button
            className='px-2 py-2 bg-orange text-white font-bold rounded-md shadow-md'
            onClick={openAdd}
          >
            + Tambah Truk
          </button>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Nomor Polisi</th>
            <th className="py-2 px-4 border-b">Tipe</th>
            <th className="py-2 px-4 border-b">Kapasitas</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.licensePlate}</td>
              <td className="py-2 px-4 border-b">{item.type}</td>
              <td className="py-2 px-4 border-b">{item.capacity}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow 
                  view={["DETAIL", "DELETE"]}
                  onViewClick={() => openModal(item)} 
                  onDeleteClick={()=>openDelete(item)} 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.trucks.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={state.trucks} setCurrentData={(current: Truck[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
      <ModalDetail isOpen={isModalOpen} isLog={false} onClose={closeModal} keys={keys} data={selectedData} onUpdate={(data:Truck)=>updatetruck(data)} />
      <ModalAdd title="Tambah Truk" isOpen={isAddOpen} onClose={closeAdd} keys={keys} data={addData} handleAdd={(data: Truck) => addTruck(data)} />
      <ModalDelete isOpen={isDeleteOpen} onClose={closeDelete} data={selectedData} onDelete={removeTruck} />
    </div>
  );
};

export default DataTruck;
