import { useEffect, useState } from "react";
import { LogReport, logReportDefault } from "../types/logReport";
import apiGetLogReport from "../api/logReport/apiGetLogReport";
import apiUpdateLogReport from "../api/logReport/apiUpdateLogReport";
import apiAddLogReport from "../api/logReport/apiAddLogReport";
import {
  AddLogReportReq,
  UpdateLogReportReq,
} from "../types/logReportContract";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useLogReport = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(logReportDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: LogReport | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetLogReport(id);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const update = async (data: UpdateLogReportReq) => {
    if (!id) throw "id is undefined";

    let resData: LogReport | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiUpdateLogReport(id, data);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: AddLogReportReq) => {
    let resData: LogReport | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiAddLogReport(data);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    update,
    add,
  };
};

export default useLogReport;
