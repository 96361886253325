import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import Pagination from '../../../components/public/Pagination';
import ModalAdd from '../../../components/admin/ModalAddData';

import useSearch from '../../../hooks/useSearch';
import { User } from '../../../types/user';
import useUserList from '../../../hooks/useUserList';
import { UserRole } from '../../../types/user';
import useUser from '../../../hooks/useUser';

import useAuth from '../../../hooks/useAuth';
import { SignUpReq } from '../../../types/authContract';

import { useAppContext } from '../../../contexts/DataContext';
import ModalDetail from '../../../components/admin/ModalDetail';
import ModalDelete from '../../../components/admin/ModalDelete';
import { UpdateUserReq } from '../../../types/userContract';

interface reportProps {
  searchTerm: string
}

const DataDriver: React.FC<reportProps> = ({ searchTerm }) => {
  const { state } = useAppContext();

  const {
    get: getJanitorList,
  } = useUserList();
  const { signUp } = useAuth();

  // selected data and id state
  const [selectedData, setSelectedData] = useState<any>(null);
  const {
    update: updateUser,
    remove: removeUser
  } = useUser(selectedData?.id);

  useEffect(() => {
    (async () => {
      const janitorQuery = {
        role: UserRole.Janitor,
        limit: 100
      };
      if (state.drivers.length === 0) await getJanitorList(janitorQuery);
    })()
  }, [])

  const [currentData, setCurrentData] = useState<User[]>([])

  //search handle
  const searchOptions = { keys: ['name'] };
  const { data: resultData, handleSearch } = useSearch(state.drivers, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const keys = [
    { title: 'Nama', key: 'name' }, 
    { title: 'Nama pengguna', key: 'username' },
    { title: 'Peran', key: 'role' },
    { title: 'Kata sandi', key: 'password' }
  ]
  const dataview = searchTerm.length === 0 ? currentData : resultData;

  //modal add handling
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [addData, setAddData] = useState<SignUpReq>({
    name: "",
    username: "",
    password: "",
    role: UserRole.Janitor,
  })
  const openAdd = () => {
    setIsAddOpen(true)
  }
  const closeAdd = () => {
    setIsAddOpen(false)
  }

  //modal detail handling
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = (data: any) => {
    setSelectedData(data);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setSelectedData(null);
    setIsModalOpen(false);
  };

  // modal delete handling
  const [isDeleteOpen, setIsDeleteOpen] = useState(false)
  const openDelete = (data: any) => {
    setSelectedData(data);
    setIsDeleteOpen(true)
  }
  const closeDelete = () => {
    setIsDeleteOpen(false)
  }

  return (
    <div className="container mx-auto mt-24 flex flex-col gap-4">
      <div className='flex flex-row justify-between'>
        <div className='text-2xl font-bold'>
          Data Petugas Kebersihan
        </div>
        <div>
          <button
            className='px-2 py-2 bg-orange text-white font-bold rounded-md shadow-md'
            onClick={openAdd}
          >
            + Tambah Petugas
          </button>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Username</th>
            <th className="py-2 px-4 border-b">Nama</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.username}</td>
              <td className="py-2 px-4 border-b">{item.name}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow
                  view={["DETAIL", "DELETE"]}
                  onViewClick={() => openModal(item)}
                  onDeleteClick={() => openDelete(item)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {state.drivers.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={state.drivers} setCurrentData={(current: User[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
      <ModalAdd title="Tambah Akun Driver" isOpen={isAddOpen} onClose={closeAdd} keys={keys} data={addData} handleAdd={(data: SignUpReq) => signUp(data)} />
      <ModalDetail isOpen={isModalOpen} isLog={false} onClose={closeModal} keys={keys} data={selectedData} onUpdate={(data: UpdateUserReq) => updateUser(data)} />
      <ModalDelete isOpen={isDeleteOpen} onClose={closeDelete} data={selectedData} onDelete={removeUser} />
    </div>
  );
};

export default DataDriver;
