import React, { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { ErrorI } from "../../types/api";

const Signin: React.FC = () => {
  const [username, setUsername] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate()

  const { signIn } = useAuth();

  const handleSignIn = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
      const userData = { username, password };
      // Panggil fungsi signIn dari useAuth
      const { error } = await signIn(userData);
      if (error) {
        setError((error as ErrorI).message);
        return;
      }
      // SignIn berhasil, tambahkan logika redirect atau tindakan lainnya
      navigate("/", { replace: true });
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-cover bg-center bg-blockImage lg:bg-fullImage">
      <div className="w-full max-w-sm bg-white p-8 m-8 rounded-xl shadow-xl border bg-opacity-60 border-gray-300">
        <h2 className="text-2xl mb-6 font-semibold">Masuk</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleSignIn}>
          <div className="mb-4">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-600"
            >
              Nama pengguna
            </label>
            <input
              type="text"
              id="username"
              className="mt-1 p-2 w-full border rounded"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="mb-4">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-600"
            >
              Kata sandi
            </label>
            <input
              type="password"
              id="password"
              className="mt-1 p-2 w-full border rounded"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button
            type="submit"
            className="bg-blue text-white py-2 px-4 rounded hover:bg-blue-600"
          >
            Masuk
          </button>
        </form>
      </div>
    </div>
  );
};

export default Signin;
