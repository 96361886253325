import { LogRoute } from "../../types/logRoute";
import { GetLogRouteListReqQuery } from "../../types/logRouteContract";
import Api from "../api";

export default async function apiGetLogRouteList(
  query?: GetLogRouteListReqQuery
) {
  return await Api.get<LogRoute[]>({
    input: "/api/v1/log-routes",
    query,
  });
}
