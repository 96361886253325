export interface LogDump {
  id: string;
  createdAt: string;
  updatedAt: string;
  dumpId: string;
  measuredVolume: number;
}

export const logDumpDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  dumpId: "",
  measuredVolume: 0,
} as LogDump;
