import { LogCollection } from "../../types/logCollection";
import { AddLogCollectionReq } from "../../types/logCollectionContract";
import Api from "../api";

export default async function apiAddLogCollection(data: AddLogCollectionReq) {
  const body = JSON.stringify(data);

  return await Api.post<LogCollection>({
    input: "/api/v1/log-collection",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
