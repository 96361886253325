import { Mcu } from "../../types/mcu";
import { GetMcuListReqQuery } from "../../types/mcuContract";
import Api from "../api";

export default async function apiGetMcuList(query?: GetMcuListReqQuery) {
  return await Api.get<Mcu[]>({
    input: "/api/v1/mcus",
    query,
  });
}
