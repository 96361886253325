import { useEffect, useState } from "react";
import { GetMapSectorListReqQuery } from "../types/mapSectorContract";
import { MapSector } from "../types/mapSector";
import apiGetMapSectorList from "../api/mapSector/apiGetMapSectorList";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useMapSectorList = (query?: GetMapSectorListReqQuery) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<MapSector[]>([]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async (newQuery?: GetMapSectorListReqQuery) => {
    if (newQuery) query = newQuery;

    let resData: MapSector[] | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetMapSectorList(query);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
  };
};

export default useMapSectorList;
