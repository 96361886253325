import { useEffect, useState } from "react";
import { Dump, dumpDefault } from "../types/dump";
import apiGetDump from "../api/dump/apiGetDump";
import apiUpdateDump from "../api/dump/apiUpdateDump";
import apiDeleteDump from "../api/dump/apiDeleteDump";
import apiAddDump from "../api/dump/apiAddDump";
import { AddDumpReq, UpdateDumpReq } from "../types/dumpContract";
import { useAppContext } from "../contexts/DataContext";
import { useDisplayContext } from "../contexts/DisplayContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useDump = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { notify } = useDisplayContext();
  const { dispatch } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(dumpDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: Dump | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetDump(id);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const update = async (data: UpdateDumpReq) => {
    if (!id) throw "id is undefined";

    let resData: Dump | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiUpdateDump(id, data);

      setData(res.json.data);
      resData = res.json.data;

      notify.info(`Berhasil memperbaharui TPS ${res.json.data.name}`)
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal memperbaharui data")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const remove = async () => {
    if (!id) throw "id is undefined";

    let resData: string | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      await apiDeleteDump(id);

      dispatch({
        type: "DELETE",
        payload: {
          type: "dumps",
          id: id,
        },
      });

      resData = id

      notify.info("Berhasil menghapus data")
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal menghapus data")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: AddDumpReq) => {
    let resData: Dump | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);
      const res = await apiAddDump(data);

      setData(res.json.data);

      dispatch({
        type: "ADD",
        payload: {
          type: "dumps",
          data: res.json.data,
        },
      });
      
      resData = res.json.data

      notify.info(`Berhasil menambah TPS ${res.json.data.name}`)
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal menambah TPS")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    update,
    remove,
    add,
  };
};

export default useDump;
