import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { getToken } from "../utils/token";
import { useAuthContext } from "./AuthContext";

// Define WebSocketContext type
type WebSocketContextType = {
  wsRef: React.MutableRefObject<WebSocket | undefined>;
};

// Create WebSocketContext
const WebSocketContext = createContext<WebSocketContextType | undefined>(
  undefined
);

// Create WebSocketProvider component
type WebSocketProviderProps = {
  children: React.ReactNode;
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  children,
}) => {
  const { auth } = useAuthContext();
  const wsRef = useRef<WebSocket>();
  const [wsState, setWsState] = useState<number>();

  useEffect(() => {
    if (!wsRef.current && auth.token) {
      wsRef.current = new WebSocket(
        `${process.env.REACT_APP_WS_BASE_URL}/api/v1/push_notification?token=${auth.token}`
      );

      // Add event listeners and handle connection logic here
      wsRef.current.addEventListener("close", onClose);
      wsRef.current.addEventListener("error", onError);
      wsRef.current.addEventListener("message", onMessage);
      wsRef.current.addEventListener("open", onOpen);

      return () => {
        if (wsRef.current) {
          if (wsRef.current.readyState === 1) {
            wsRef.current.close();
            wsRef.current.removeEventListener("close", onClose);
            wsRef.current.removeEventListener("error", onError);
            wsRef.current.removeEventListener("message", onMessage);
            wsRef.current.removeEventListener("open", onOpen);
          }
        }
      };
    }
  }, [auth.token]);

  useEffect(() => {
    if (wsState == 1) {
      const sendPing = () => {
        if (wsRef.current) {
          wsRef.current.send("PING");
        }
      };

      const interval = setInterval(sendPing, 3000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [wsState]);

  const onClose = (event: CloseEvent) => {
    setWsState(0);
    console.log("WebSocket closed:", event.code);
  };

  const onError = (event: Event) => {
    console.log("WebSocket error:", event);
  };

  const onMessage = (event: MessageEvent<any>) => {
    console.log("Received message:", event.data);
  };

  const onOpen = (event: Event) => {
    setWsState(1);
    console.log("WebSocket open:", event);
  };

  return (
    <WebSocketContext.Provider value={{ wsRef }}>
      {children}
    </WebSocketContext.Provider>
  );
};

// Create a custom hook to use WebSocketContext
export const useWebSocket = () => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
