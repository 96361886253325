import { useEffect, useMemo, useState } from "react";
import { Auth, authDefault } from "../types/auth";
import { SignInReq, SignUpReq } from "../types/authContract";
import apiSignIn from "../api/auth/apiSignIn";
import { getToken, setToken, unsetToken } from "../utils/token";
import apiSignUp from "../api/auth/apiSignUp";
import { useAppContext } from "../contexts/DataContext";
import { User } from "../types/user";
import { useDisplayContext } from "../contexts/DisplayContext";
import apiGetAuth from "../api/auth/apiGetAuth";
import { useAuthContext } from "../contexts/AuthContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useAuth = () => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { notify } = useDisplayContext();
  const { auth: data, setAuth: setData } = useAuthContext();
  const { dispatch } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);

  const isLoggedIn = useMemo(() => {
    if (data.token) {
      return true;
    }
    return false;
  }, [data]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const signIn = async (data: SignInReq) => {
    let resData: Auth | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiSignIn(data);
      setToken(res.json.data.token);
      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const signUp = async (data: SignUpReq) => {
    let resData: User | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiSignUp(data);

      dispatch({
        type: "ADD",
        payload: {
          type: "drivers",
          data: res.json.data,
        },
      });

      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal Menambahkan User")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const signOut = () => {
    setIsLoading(true);

    unsetToken();
    setData(authDefault);

    setIsLoading(false);
  };

  const getAuth = async() => {
    const token = getToken();
    if (!token) return;
    
    let resData: Auth | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetAuth();
      setToken(res.json.data.token);
      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false)
    }

    return { resData, error };
  }

  return {
    data,
    isLoading,
    isLoggedIn,
    signIn,
    signUp,
    signOut,
    getAuth,
  };
};

export default useAuth;
