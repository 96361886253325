import { useEffect, useState } from "react";
import { Truck, truckDefault } from "../types/truck";
import apiGetTruck from "../api/truck/apiGetTruck";
import apiUpdateTruck from "../api/truck/apiUpdateTruck";
import apiDeleteTruck from "../api/truck/apiDeleteTruck";
import apiAddTruck from "../api/truck/apiAddTruck";
import { useAppContext } from "../contexts/DataContext";
import { useDisplayContext } from "../contexts/DisplayContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useTruck = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { notify } = useDisplayContext();
  const { dispatch } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(truckDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: Truck | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetTruck(id);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const update = async (data: Truck) => {
    if (!id) throw "id is undefined";

    let resData: Truck | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiUpdateTruck(id, data);

      setData(res.json.data);

      dispatch({
        type: "UPDATE",
        payload: {
          type: "trucks",
          data: res.json.data,
        },
      });

      resData = res.json.data;

      notify.info(`Berhasil memperbaharui data truk ${res.json.data.licensePlate}`)
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal memperbaharui data truk")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const remove = async () => {
    if (!id) throw "id is undefined";

    let resData: string | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      await apiDeleteTruck(id);

      dispatch({
        type: "DELETE",
        payload: {
          type: "trucks",
          id: id,
        },
      });

      resData = id;
      notify.info("Berhasil menghapus data truk")
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal menghapus data truk")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: Truck) => {
    let resData: Truck | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiAddTruck(data);

      setData(res.json.data);

      dispatch({
        type: "ADD",
        payload: {
          type: "trucks",
          data: res.json.data,
        },
      });

      resData = res.json.data;

      notify.info(`Berhasil menambahkan truk ${res.json.data.licensePlate}`)
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("gagal menambahkan data truk")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    update,
    remove,
    add,
  };
};

export default useTruck;
