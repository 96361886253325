export interface LogCollection {
  id: string;
  createdAt: string;
  updatedAt: string;
  routeId: string;
  dumpId: string;
  volume: number;
  status: string;
  note: string;
}

export const logCollectionDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  routeId: "",
  dumpId: "",
  volume: 0,
  status: "",
  note: "",
} as LogCollection;
