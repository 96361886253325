import React, { useEffect, useState } from 'react';

interface PaginationProps<T> {
  data: T[]
  setCurrentData: (current:T[]) => void
}

const itemsPerPage = 6;

const Pagination: React.FC<PaginationProps<any>> = ({ data, setCurrentData }) => {
  const [currentPage, setCurrentPage] = useState(1);

  // Menghitung indeks batas atas dan bawah untuk data pada halaman saat ini
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  useEffect(() => {
    // Mengambil data untuk halaman saat ini
    const currentData = data.slice(startIndex, endIndex);
    setCurrentData(currentData);
  }, [data, currentPage])

  const totalPages = Math.ceil(data.length / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <div>
      <button
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className='text-2xl font-bold'
      >
        <span>&lt;</span>
      </button>

      <span> Page {currentPage} of {totalPages} </span>

      <button
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className='text-2xl font-bold'
      >
        <span>&gt;</span>
      </button>
    </div>
  );
};

export default Pagination;