import { useEffect, useState } from "react";
import apiGetRoute from "../api/route/apiGetRoute";
import { GetRouteReqQuery, GetRouteRes } from "../types/routeContract";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useRoute = (query?: GetRouteReqQuery) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetRouteRes[]>([]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async (newQuery?: GetRouteReqQuery) => {
    if (newQuery) query = newQuery;

    let resData: GetRouteRes[] | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetRoute(query);

      setData(res.json.data);
      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
  };
};

export default useRoute;
