import React, { useRef, useEffect, useState } from 'react';
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import iconDefault from '../../assets/iconDefault.png'
import useDumpList from '../../hooks/useDumpList';
import { Dump, DumpType, dumpDefault } from '../../types/dump';
import SearchBar from '../../components/public/Searchbar';
import useMapSectorList from '../../hooks/useMapSectorList';
import useFilter from '../../hooks/useFilter';
import ModalDumpInfo from '../../components/admin/map/ModalDumpInfo';
import useSearch from '../../hooks/useSearch';
import { useAppContext } from '../../contexts/DataContext';

const TPSMap: React.FC = () => {
    const { state } = useAppContext()
    const {
        get: getDump
    } = useDumpList()

    const {
        data: sectorList,
        get: getSector
    } = useMapSectorList()

    // state show marker information
    const [showInfo, setShowInfo] = useState(false);
    const [selectedDump, setSelectedDump] = useState<Dump>(dumpDefault);

    const [selectedOpt, setSelectedOpt] = useState("");
    const filterOptions = { keys: ['mapSectorId'] }
    const { handleFilter, data: filterData } = useFilter(state.dumps, filterOptions)

    const [searchTerm, setSearchTerm] = useState<string>('')
    const searchOption = { keys: ['name'] }
    const { handleSearch, data: resultData } = useSearch(selectedOpt === "" ? state.dumps : filterData, searchOption)

    const mapRef = useRef<L.Map | null>(null);

    const customMarkerIcon = L.icon({
        iconUrl: iconDefault,
        iconSize: [32, 32], // Set the size of your icon
        iconAnchor: [16, 32], // Adjust the anchor point if needed
        popupAnchor: [0, -32], // Adjust the popup anchor if needed
    });

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100,
                type: DumpType.TempDump
            }
            if (state.dumps.length === 0) await getDump(query)

            const sectorQuery = {
                limit: 100,
            }
            if (sectorList.length === 0) await getSector(sectorQuery)
        })()

        if (mapRef.current) {
            mapRef.current.remove();
        }

        if (selectedOpt !== "") { handleFilter(selectedOpt) }
        if (searchTerm.length !== 0) { handleSearch(searchTerm) }

        // Inisialisasi peta
        const map = L.map("map").setView([-7.798484558411561, 110.3724409997804], 13);

        const key = 'tDMeMZZ9lS5QizYGtekk'
        // Menambahkan tile layer dari OpenStreetMap
        L.tileLayer(`https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${key}`, {
            attribution: "© B-COP",
        }).addTo(map);

        const dataMarker = searchTerm.length === 0 ? selectedOpt === "" ? state.dumps : filterData : resultData;

        (dataMarker as Dump[])?.forEach((loc) => {
            if (loc) {
                const marker = L.marker([loc.coordinate.latitude, loc.coordinate.longitude], {
                    icon: customMarkerIcon,
                    interactive: true,
                    zIndexOffset: 1000,
                }).addTo(map);
                // Menambahkan event listener untuk klik pada setiap marker
                marker.on("click", () => {
                    // Handle klik event di sini, misalnya, tampilkan informasi atau ubah state
                    setSelectedDump(loc);
                    setShowInfo(true);
                });
            }
        });

        mapRef.current = map;
    }, [state.dumps, selectedOpt, searchTerm])

    return <>
        <div className='flex flex-col gap-16 px-12 lg:px-24 py-24 lg:h-screen lg:text-left'>
            <div className='flex flex-col sm:flex-row gap-12 justify-between'>
                <div className='text-2xl font-bold'>
                    PETA LOKASI TPS
                </div>
                <div>
                    <SearchBar
                        pathname="/"
                        selectedOpt={selectedOpt}
                        setSelectedOpt={(selected: string) => setSelectedOpt(selected)}
                        options={sectorList?.map((sector) => ({
                            value: sector.id,
                            label: sector.name,
                        }))}
                        searchTerm={searchTerm}
                        setSearchTerm={(term: string) => setSearchTerm(term)}
                    />
                </div>
            </div>
            <div className='flex flex-row gap-2 justify-between px-2 py-2 shadow-md rounded-lg'>
                <div id="map" style={{ height: "400px" }} className="flex-1 w-full">

                </div>
                {showInfo ?
                    <div className='flex-1'>
                        <div className='flex flex-col gap-2'>
                            <ModalDumpInfo
                                isOpen={showInfo}
                                onClose={() => setShowInfo((prev) => !prev)}
                                data={selectedDump}
                            />
                        </div>
                    </div>
                    :
                    <></>
                }
            </div>
        </div>
    </>
};

export default TPSMap;