import { Mcu } from "../../types/mcu";
import { UpdateMcuReq } from "../../types/mcuContract";
import Api from "../api";

export default async function apiUpdateMcu(id: string, data: UpdateMcuReq) {
  const body = JSON.stringify(data);

  return await Api.patch<Mcu>({
    input: `/api/v1/mcu/${id}`,
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
