import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

interface NavbarProps {
    links: { path: string; label: string }[];
}

const Navbar: React.FC<NavbarProps> = ({ links }) => {
    const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

    useEffect(() => {
        if (isMobileMenuOpen) {
            window.addEventListener("click", closeMobileMenu)
        }

        return () => {
            window.removeEventListener("click", closeMobileMenu)
        };
    }, [isMobileMenuOpen])

    const toggleMobileMenu = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        setMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setMobileMenuOpen(false)
    }

    return (
        <nav className="flex justify-center sticky-nav px-8 pt-4">
            <div className="h-14 flex items-center justify-between bg-basicGreen py-2 shadow-lg  text-white rounded-xl w-full px-4 sm:px-24">
                <div className="font-bold text-lg">
                    <Link to='/'>B-COP</Link>
                </div>

                {/* Burger menu icon for mobile */}
                <div className="lg:hidden cursor-pointer" onClick={toggleMobileMenu}>
                    <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                            d="M4 6h16M4 12h16m-7 6h7"></path>
                    </svg>
                </div>

                {/* Links for larger screens */}
                <ul className="hidden lg:flex space-x-4 gap-2 items-center">
                    {links.map((link, index) => (
                        link.label === "Pelaporan" ?
                            <li key={index} className="text-orange hover:text-white px-4 py-1 rounded-lg bg-white hover:bg-orange">
                                <Link to={link.path}>{link.label}</Link>
                            </li>
                            :
                            <li key={index} className="hover:text-gray-300">
                                <Link to={link.path}>{link.label}</Link>
                            </li>
                    ))}
                </ul>

                {/* Mobile menu */}
                {isMobileMenuOpen && (
                    <div className="lg:hidden absolute top-20 w-full left-0 px-8">
                        <div onClick={(e) => e.stopPropagation()} className="bg-basicGreen p-4 rounded-xl">
                            <ul className="flex flex-col space-y-2">
                                {links.map((link, index) => (
                                    link.label === "Pelaporan" ?
                                        <li key={index} className="text-orange hover:text-white px-4 py-1 rounded-lg bg-white hover:bg-orange">
                                            <Link onClick={closeMobileMenu} to={link.path}>{link.label}</Link>
                                        </li>
                                        :
                                        <li key={index} className="hover:text-gray-300">
                                            <Link onClick={closeMobileMenu} to={link.path}>{link.label}</Link>
                                        </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default Navbar;
