import { SignUpReq } from "../../types/authContract";
import { User } from "../../types/user";
import Api from "../api";

export default async function apiSignUp(data: SignUpReq) {
  const body = JSON.stringify(data);

  return await Api.post<User>({
    input: "/api/v1/auth/signup",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
