import { Coordinate } from "./coordinate";

export interface LogReport {
  id: string;
  createdAt: string;
  updatedAt: string;
  reporterName: string;
  mediaId: string;
  dumpId?: string;
  coordinate: Coordinate;
  type: string;
  description: string;
  status: string;
}

export const logReportDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  reporterName: "",
  mediaId: "",
  dumpId:"",
  coordinate: {
    latitude: 0,
    longitude: 0,
  },
  type: "",
  description: "",
  status: "",
} as LogReport;
