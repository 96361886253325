import React, { useEffect, useState } from 'react';
import axios from 'axios';

interface IFrameProps {
  videoId: string;
  title: string;
  width?: string;
  height?: string;
}

const IFrame: React.FC<IFrameProps> = ({ videoId, title, width = '100%', height = '=300px' }) => {
  const [embedUrl, setEmbedUrl] = useState<string | null>(null);

  useEffect(() => {
    const fetchVideoEmbedUrl = async () => {
      try {
        const response = await axios.get(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${videoId}&key=AIzaSyBa5Yb9IPLEgBCfwimfRrlvWlZsqsi2BJg`
        );

        const video = response.data.items[0];
        const videoEmbedUrl = `https://www.youtube.com/embed/${videoId}`;

        setEmbedUrl(video ? videoEmbedUrl : null);
      } catch (error) {
        console.error('Error fetching YouTube video data:', error);
        setEmbedUrl(null);
      }
    };

    fetchVideoEmbedUrl();
  }, [videoId]);

  return (
    <div>
      {embedUrl ? (
        <iframe
          title={title}
          src={embedUrl}
          width={width}
          height={height}
          frameBorder="0"
          allowFullScreen
        />
      ) : (
        <p>Error loading YouTube video</p>
      )}
    </div>
  );
};


export default IFrame