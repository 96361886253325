import React, { useEffect, useState } from 'react';
import useMcuList from '../../../hooks/useMcuList';
import MCUCard from '../../../components/admin/MCUCard';
import ModalAdd from '../../../components/admin/ModalAddData';
import { AddMcuReq } from '../../../types/mcuContract';
import useMcu from '../../../hooks/useMcu';
import { useAppContext } from '../../../contexts/DataContext';
import { useLoadingContext } from '../../../contexts/LoadingContext';
import { Mcu } from '../../../types/mcu';

const MCU: React.FC = () => {
    const { incrementLoading, decrementLoading } = useLoadingContext()
    const { add: addMCU } = useMcu()
    const { state } = useAppContext()

    const {
        isLoading: isLoadingMCUList,
        get: getMCUList
    } = useMcuList()

    useEffect(() => {
        isLoadingMCUList ? incrementLoading() : decrementLoading();
    }, [isLoadingMCUList])

    useEffect(() => {
        (async () => {
            const query = {
                limit: 100
            };
            if (state.MCUs.length === 0) await getMCUList(query);
        })()
    }, [])

    const keys = [{ title: "TPS", key: "dumpId" }]
    //modal add handling
    const [isAddOpen, setIsAddOpen] = useState(false)
    const [addData, setAddData] = useState<AddMcuReq>({
        dumpId: ""
    })
    const openAdd = () => {
        setIsAddOpen(true)
    }
    const closeAdd = () => {
        setIsAddOpen(false)
    }

    return <>
        <div className='container mx-auto mt-24 flex flex-col gap-4'>
            <div className='flex flex-row justify-between'>
                <div className='text-2xl font-bold'>
                    Data Unit Mikrokontroler
                </div>
                <div>
                    <button
                        className='px-2 py-2 bg-orange text-white font-bold rounded-md shadow-md'
                        onClick={openAdd}
                    >
                        + Tambah Unit
                    </button>
                </div>
            </div>
            <div>
                {state.MCUs.length === 0 ?
                    <div className="container mx-auto mt-8">
                        Data tidak ditemukan
                        {/* <MCUCard /> */}
                    </div>
                    :
                    <div className='flex flex-col gap-2'>
                        {(state.MCUs as Mcu[]).map((item) => (
                            <MCUCard data={item}/>
                        ))}
                    </div>
                }
            </div>
            <ModalAdd
                title="Tambah Unit Mikrokontroler" isOpen={isAddOpen} onClose={closeAdd} data={addData} keys={keys} handleAdd={(data: AddMcuReq) => addMCU(data)}
            />
        </div>
    </>
};

export default MCU;