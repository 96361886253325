import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import Pagination from '../../../components/public/Pagination';
import useSearch from '../../../hooks/useSearch';
import { LogRoute } from '../../../types/logRoute';
import useLogRouteList from '../../../hooks/useLogRouteList';

interface reportProps {
  searchTerm: string
}

const DataRoute: React.FC<reportProps> = ({ searchTerm }) => {
  const {
    data: routeListData,
    get: getRouteList,
  } = useLogRouteList();

  useEffect(() => {
    (async () => {
      const query = {
        limit: 100,
      };
      if (routeListData.length === 0) await getRouteList(query);
    })()
  }, [])

  const [currentData, setCurrentData] = useState<LogRoute[]>([])

  //search handle
  const searchOptions = { keys: ['type'] };
  const { data: resultData, handleSearch } = useSearch(routeListData, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const dataview = searchTerm.length === 0 ? currentData : resultData;

  const handleViewClick = () => {
    // Add logic for handling view action
    console.log('View Clicked');
  };

  const handleDeleteClick = () => {
    // Add logic for handling delete action
    console.log('Delete Clicked');
  };

  return (
    <div className="container mx-auto mt-24">
      ini data rute
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Id Truk</th>
            <th className="py-2 px-4 border-b">Id Driver</th>
            <th className="py-2 px-4 border-b">Tanggal</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.truckId}</td>
              <td className="py-2 px-4 border-b">{item.driverId}</td>
              <td className="py-2 px-4 border-b">{item.createdAt}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow 
                  view={["DETAIL"]}
                  onViewClick={handleViewClick} 
                  onDeleteClick={handleDeleteClick} 
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {routeListData.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={routeListData} setCurrentData={(current: LogRoute[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
    </div>
  );
};

export default DataRoute;
