import React, { useEffect, useState } from "react";
import MiniMap from "./MiniMap";
import { Coordinate } from "../../types/coordinate";
import { Dump, DumpType } from "../../types/dump";
import useMapSectorList from "../../hooks/useMapSectorList";
import { UserRole } from "../../types/user";
import useDumpList from "../../hooks/useDumpList";
import { useAppContext } from "../../contexts/DataContext";
import IconClose from "../public/IconClose";

interface DataItem {
    [key: DumpType | number | string]: any; // Representasi umum untuk atribut JSON apapun
}

interface addProps {
    title: string
    isOpen: boolean
    onClose: () => void
    keys: { title: string, key: string }[]
    data: DataItem
    handleAdd: (data: any) => void
}

const ModalAdd: React.FC<addProps> = ({ title, isOpen, onClose, keys, data, handleAdd }) => {
    const [addData, setAddData] = useState<typeof data>(data)
    const { state } = useAppContext()

    const {
        data: sectorListData,
        get: getSectorList,
    } = useMapSectorList();

    const {
        get: getDumpList,
    } = useDumpList();

    useEffect(() => {
        (async () => {
            const sectorQuery = {
                limit: 100,
            };
            if (sectorListData.length === 0) await getSectorList(sectorQuery);

            const dumpQuery = {
                type: DumpType.TempDump,
                limit: 100,
            };
            if (state.dumps.length === 0) await getDumpList(dumpQuery);
        })()
    }, [])

    if (!isOpen) {
        return null;
    }

    const submit = () => {
        handleAdd(addData)
        onClose()
    }

    const renderTypeInput = (key: string | number) => {
        if (typeof key === "number") { return 'number' }
        else { return 'text' }
    }

    const renderInput = () => {
        return (
            <div className='w-full flex flex-col justify-between gap-2'>
                <p className="text-lg font-bold mb-2">{title}</p>
                {keys?.map((item, index) => (
                    <div key={index} className='w-full flex flex-row gap-2'>
                        <div className='flex flex-row gap-2 w-full items-center'>
                            <div className='flex flex-row w-1/2 justify-between text-sm'>
                                <p>{item.title}</p>
                                <p>:</p>
                            </div>
                            {item.key === 'type' && addData.coordinate ?
                                <select
                                    value={addData[item.key]}
                                    onChange={(e) => {
                                        // const value = e.target.value === DumpType.FinalDump ? DumpType.FinalDump : e.target.value === DumpType.TempDump ? DumpType.TempDump : DumpType._;
                                        const value: DumpType = e.target.value as DumpType
                                        setAddData((prevData: any) => ({ ...prevData, [item.key]: value }))
                                    }}
                                    className="w-full"
                                >
                                    <option value="" disabled>
                                        Pilih opsi
                                    </option>
                                    {(Object.keys(DumpType) as Array<keyof typeof DumpType>).map((key) => (
                                        <option value={DumpType[key]} key={key} >
                                            {DumpType[key].split("_").map(key=> {
                                                if(!key) return "";
                                                const k = key.toLowerCase();
                                                return k[0].toUpperCase()+k.slice(1)
                                            }).join(" ")}
                                        </option>
                                    ))}
                                </select>
                            : item.key === 'mapSectorId' ?
                                <select
                                    value={sectorListData?.find((sector) =>
                                        sector.id === addData[item.key]
                                    ) || addData[item.key]}
                                    onChange={(e) => { setAddData((prevData: any) => ({ ...prevData, [item.key]: e.target.value })) }}
                                    className="w-full"
                                >
                                    <option value="" disabled>
                                        Pilih Sektor
                                    </option>
                                    {sectorListData?.map((sector) =>
                                        <option key={sector.id} value={sector.id}>{sector.name}</option>
                                    )}
                                </select>
                            : item.key === 'capacity' ?
                                <div className="w-full flex gap-x-1">
                                    <input
                                        type={renderTypeInput(addData[item.key])}
                                        className='border-2 w-full px-1 rounded-md'
                                        placeholder={item.title}
                                        value={addData[item.key]}
                                        onChange={
                                            (e) => {
                                                const value = e.target.value;
                                                setAddData((prevData: any) => ({ ...prevData, [item.key]: renderTypeInput(addData[item.key]) === 'number' ? parseInt(value, 10) : value }))
                                            }
                                        } 
                                    />
                                    <span>m<sup>3</sup></span>
                                </div>
                            : item.key === 'fuelConsumption' ?
                                <div className="w-full flex gap-x-1">
                                    <input
                                        type={renderTypeInput(addData[item.key])}
                                        className='border-2 w-full px-1 rounded-md'
                                        placeholder={item.title}
                                        value={addData[item.key]}
                                        onChange={
                                            (e) => {
                                                const value = e.target.value;
                                                setAddData((prevData: any) => ({ ...prevData, [item.key]: renderTypeInput(addData[item.key]) === 'number' ? parseInt(value, 10) : value }))
                                            }
                                        } 
                                    />
                                    <span>l/m</span>
                                </div>
                            : item.key === 'role' ?
                                <input
                                    type={renderTypeInput(addData[item.key])}
                                    className='border-2 w-full px-1 rounded-md'
                                    placeholder={item.title}
                                    value={(() => {
                                        const role = UserRole.Janitor.toLowerCase();
                                        return role[0].toUpperCase() + role.slice(1)
                                    })()}
                                    disabled
                                />
                            : item.key === 'isActive' ?
                                <div className="w-full flex flex-row gap-2">
                                    <label className="flex items-center gap-x-1">
                                        <input
                                            type="radio"
                                            value="1"
                                            checked={addData[item.key] === true}
                                            onChange={(e) => {
                                                const value: boolean = e.target.value === "1" ? true : false
                                                setAddData((prevData: any) => ({ ...prevData, [item.key]: value }))
                                            }}
                                        />
                                        Ya
                                    </label>
                                    <label className="flex items-center gap-x-1">
                                        <input
                                            type="radio"
                                            value="2"
                                            checked={addData[item.key] === false}
                                            onChange={(e) => {
                                                const value: boolean = e.target.value === "1" ? true : false
                                                setAddData((prevData: any) => ({ ...prevData, [item.key]: value }))
                                            }}
                                        />
                                        Tidak
                                    </label>
                                </div>
                            : item.key === "dumpId" ?
                                <select
                                    value={addData[item.key]}
                                    onChange={(e) => setAddData((prevData: any) => ({ ...prevData, [item.key]: e.target.value }))}
                                    className='w-full border-2 rounded-md py-2'
                                >
                                    {(state.dumps as Dump[])?.map((item) => (
                                        <option key={item.id} value={item.id}>{item.name}</option>
                                    ))}
                                </select>
                            : <input
                                type={renderTypeInput(addData[item.key])}
                                className='border-2 w-full px-1 rounded-md'
                                placeholder={item.title}
                                value={addData[item.key]}
                                onChange={
                                    (e) => {
                                        const value = e.target.value;
                                        setAddData((prevData: any) => ({ ...prevData, [item.key]: renderTypeInput(addData[item.key]) === 'number' ? parseInt(value, 10) : value }))
                                    }
                                }
                            />
                            }
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className="fixed top-0 left-0 w-full h-full flex flex-col bg-gray-500 bg-opacity-75 transition-opacity">
            <div className="ml-auto mt-[5.5rem] w-full h-full flex-1 bg-white shadow-md p-8 rounded-md max-w-md flex flex-col gap-4 border-2 border-blue">
                <button className="text-black w-8 h-8 ml-auto flex items-center justify-center rounded-full" onClick={onClose}>
                    <IconClose />
                </button>
                {renderInput()}
                {addData['coordinate'] ?
                    <MiniMap
                        coordinate={data.coordinate}
                        isEditable={true}
                        setCoordinate={(coor: Coordinate) => setAddData((prevData: any) => ({ ...prevData, ['coordinate']: coor }))} />
                    :
                    <></>
                }
                <div className="py-3 sm:flex sm:flex-row-reverse sm:justify-start gap-2">
                    <button
                        type="button"
                        className="bg-blue hover:bg-cyan-500 text-white py-2 px-4 rounded focus:outline-none focus:shadow-outline-red"
                        onClick={submit}
                    >
                        Simpan
                    </button>

                    <button
                        type="button"
                        className="bg-white hover:bg-red-500 hover:text-white text-red-500 border-2 border-red-500 py-2 px-4 rounded focus:outline-none focus:shadow-outline-orange"
                        onClick={onClose}
                    >
                        Batal
                    </button>
                </div>
            </div>
        </div>
    )
}
export default ModalAdd;