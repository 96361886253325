import { Truck } from "../../types/truck";
import { GetTruckListReqQuery } from "../../types/truckContract";
import Api from "../api";

export default async function apiGetTruckList(query?: GetTruckListReqQuery) {
  return await Api.get<Truck[]>({
    input: "/api/v1/trucks",
    query,
  });
}
