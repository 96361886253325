import { useEffect, useState } from "react";
import apiGetDumpList from "../api/dump/apiGetDumpList";
import { GetDumpListReqQuery } from "../types/dumpContract";
import { Dump } from "../types/dump";
import { useAppContext } from "../contexts/DataContext";
import { useDisplayContext } from "../contexts/DisplayContext";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useDumpList = (query?: GetDumpListReqQuery) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { notify } = useDisplayContext();
  const { dispatch } = useAppContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<Dump[]>([]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async (newQuery?: GetDumpListReqQuery) => {
    if (newQuery) query = newQuery;

    let resData: Dump[] | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetDumpList(query);

      setData(res.json.data);

      dispatch({
        type: 'GET',
        payload: {
          type: 'dumps',
          data: res.json.data
        }
      });

      resData = res.json.data
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
      notify.error("Gagal mendapatkan data Dump")
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
  };
};

export default useDumpList;
