import { Coordinate } from "./coordinate";

export interface MapSector {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  polygon: Coordinate[];
}

export const mapSectorDefault = {
  id: "",
  createdAt: "",
  updatedAt: "",
  name: "",
  polygon: [],
} as MapSector;
