import { Truck } from "../../types/truck";
import { AddTruckReq } from "../../types/truckContract";
import Api from "../api";

export default async function apiAddTruck(data: AddTruckReq) {
  const body = JSON.stringify(data);

  return await Api.post<Truck>({
    input: "/api/v1/truck",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
