import { Truck } from "../../types/truck";
import { UpdateTruckReq } from "../../types/truckContract";
import Api from "../api";

export default async function apiUpdateTruck(id: string, data: UpdateTruckReq) {
  const body = JSON.stringify(data);

  return await Api.patch<Truck>({
    input: `/api/v1/truck/${id}`,
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
