import React from 'react';
import {Outlet } from "react-router-dom";

const Dataview: React.FC = () => {

  return (
    <div className='lg:px-12'>
      <Outlet />
    </div>
  );
};

export default Dataview;
