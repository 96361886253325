import React from "react";
import logo from "../../assets/logo.png";

const Loading: React.FC = () => {
  return (
    <div className="fixed z-[10000] top-0 left-0 w-screen h-screen flex items-center bg-gray-500 bg-opacity-75 transition-opacity">
      <img src={logo} className="w-24 mx-auto animate-loading" />
    </div>
  );
};

export default Loading;
