import React, { createContext, ReactNode, useContext, useState } from "react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Define the type for your context
interface DisplayContextType {
    notify: {
        info: (msg: string) => void;
        error: (msg: string) => void;
    };
    isPending: boolean;
    error: boolean;
    setLoading: (state: boolean) => void;
    setError: (state: boolean) => void;
}

// Create the context with an initial value (you can provide default values here)
export const DisplayContext = createContext<DisplayContextType | undefined>(undefined);

// Define the props for your provider component
interface DisplayContextProviderProps {
    children: ReactNode;
}

// Create the provider component with types
const DisplayProvider: React.FC<DisplayContextProviderProps> = ({ children }) => {
    const [isPending, setIsPending] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const notify = {
        info: (msg: string) => {
            toast.info(msg, {
                // your toast configuration
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        },
        error: (msg: string) => {
            toast.error(msg, {
                // your toast configuration
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: "light",
            });
        }
    }

    const setLoading = (state: boolean) => {
        setIsPending(state);
    }

    const setErrorState = (state: boolean) => {
        setError(state);
    }

    return (
        <DisplayContext.Provider value={{ notify, isPending, error, setLoading, setError: setErrorState }}>
            {children}
        </DisplayContext.Provider>
    );
}

// Create a custom hook to use the context
export const useDisplayContext = () => {
    const context = useContext(DisplayContext);
    if (!context) {
        throw new Error("useDisplayContext must be used within a DisplayContextProvider");
    }
    return context;
}


export default DisplayProvider;
