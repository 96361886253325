import React, { useEffect, useState } from 'react';
import ActionButtonRow from '../../../components/admin/ActionButton';
import useMapSectorList from '../../../hooks/useMapSectorList';
import { MapSector } from '../../../types/mapSector';
import Pagination from '../../../components/public/Pagination';
import useSearch from '../../../hooks/useSearch';
import { AddMapSectorReq } from '../../../types/mapSectorContract';
import ModalAdd from '../../../components/admin/ModalAddData';
import useMapSector from '../../../hooks/useMapSector';

interface sectorProps {
  searchTerm: string
}

const DataSector: React.FC<sectorProps> = ({ searchTerm }) => {
  const {
    data: sectorListData,
    get: getSectorList,
  } = useMapSectorList();

  const{add}=useMapSector()

  useEffect(() => {
    (async () => {
      const query = {
        limit: 100,
      };
      if (sectorListData.length === 0) await getSectorList(query);
    })()
  }, [])

  const [currentData, setCurrentData] = useState<MapSector[]>([])

  //search handle
  const searchOptions = { keys: ['name'] };
  const { data: resultData, handleSearch } = useSearch(sectorListData, searchOptions)
  useEffect(() => {
    handleSearch(searchTerm)
  }, [searchTerm])

  const dataview = searchTerm.length === 0 ? currentData : resultData;

  const keys = [
    { title: 'Nama', key: 'name' },
  ];

  //modal add handling
  const [isAddOpen, setIsAddOpen] = useState(false)
  const [addData, setAddData] = useState<AddMapSectorReq>({
    name:""
  })
  const openAdd = () => {
    setIsAddOpen(true)
  }
  const closeAdd = () => {
    setIsAddOpen(false)
  }

  const handleViewClick = () => {
    // Add logic for handling view action
    console.log('View Clicked');
  };

  const handleDeleteClick = () => {
    // Add logic for handling delete action
    console.log('Delete Clicked');
  };

  return (
    <div className="container mx-auto mt-24 flex flex-col gap-4">
      <div className='flex flex-row justify-between'>
        <div className='text-2xl font-bold'>
          Data Sektor
        </div>
        <div>
          <button 
            className='px-2 py-2 bg-orange text-white font-bold rounded-md shadow-md'
            onClick={openAdd}
          >
            + Tambah Sektor
          </button>
        </div>
      </div>
      <table className="min-w-full bg-white border border-gray-300">
        <thead>
          <tr>
            <th className="py-2 px-4 border-b">Nama</th>
            <th className="py-2 px-4 border-b">Aksi</th>
          </tr>
        </thead>
        <tbody>
          {dataview?.map((item) => (
            <tr key={item.id}>
              <td className="py-2 px-4 border-b">{item.name}</td>
              <td className="py-2 px-4 border-b">
                <ActionButtonRow 
                  view={["DELETE"]}
                  onViewClick={handleViewClick} 
                  onDeleteClick={handleDeleteClick} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {sectorListData.length === 0 ?
        <div className="container mx-auto mt-8">
          Data tidak ditemukan
        </div>
        :
        <div className="container mx-auto mt-8">
          {searchTerm.length === 0 ? <Pagination data={sectorListData} setCurrentData={(current: MapSector[]) => setCurrentData(current)} /> : <></>}
        </div>
      }
      <ModalAdd title="Tambah Sector" isOpen={isAddOpen} onClose={closeAdd} keys={keys} data={addData} handleAdd={(data: AddMapSectorReq) => add(data)} />
    </div>
  );
};

export default DataSector;
