import { useEffect, useState } from "react";
import { MapSector, mapSectorDefault } from "../types/mapSector";
import apiGetMapSector from "../api/mapSector/apiGetMapSector";
import apiUpdateMapSector from "../api/mapSector/apiUpdateMapSector";
import {
  AddMapSectorReq,
  UpdateMapSectorReq,
} from "../types/mapSectorContract";
import apiDeleteMapSector from "../api/mapSector/apiDeleteMapSector";
import apiAddMapSector from "../api/mapSector/apiAddMapSector";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useMapSector = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(mapSectorDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading]);

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: MapSector | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetMapSector(id);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const update = async (data: UpdateMapSectorReq) => {
    if (!id) throw "id is undefined";

    let resData: MapSector | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiUpdateMapSector(id, data);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const remove = async () => {
    if (!id) throw "id is undefined";

    let resData: string | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      await apiDeleteMapSector(id);

      resData = id;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: AddMapSectorReq) => {
    let resData: MapSector | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiAddMapSector(data);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    update,
    remove,
    add,
  };
};

export default useMapSector;
