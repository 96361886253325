export const dumpRoute = [
  {
    id: "37a85d76-1893-4b92-85bc-99ff90de9fbd",
    truckId: "1",
    dumpIds: ["d180165c-6069-4182-b746-b5f4908ea8d2", "2a0e6568-7ef3-4329-96bf-d937fd121be1", "f3af9ea0-5b39-4d55-af80-2f86389a437b", "ebc43fe5-8576-4264-929e-32df85f3d1fd"],
    driverId: "37a85d76-1893-4b92-85bc-99ff90de9fbe",
    createdAt: "2023-11-08T14:13:53.260873Z",
    updatedAt: ""
  },
  {
    id: "37a85d76-1893-4b92-85bc-99ff90de9fex",
    truckId: "1",
    dumpIds: ["d180165c-6069-4182-b746-b5f4908ea8d2", "2a0e6568-7ef3-4329-96bf-d937fd121be1", "f3af9ea0-5b39-4d55-af80-2f86389a437b", "ebc43fe5-8576-4264-929e-32df85f3d1fd"],
    driverId: "37a85d76-1893-4b92-85bc-99ff90de9fbe",
    createdAt: "2023-11-07T14:13:53.260873Z",
    updatedAt: ""
  },
  {
    id: "37a85d76-1893-4b92-85bc-99ff90de9fgh",
    truckId: "2",
    dumpIds: ["54da4deb-e05e-498b-a017-564d2e2d035f", "6ca18987-67de-4e6f-bf98-8e4968c6b06e", "a8324d37-e677-4bd7-951d-bd9431accfe0"],
    driverId: "37a85d76-1893-4b92-85bc-99ff90de9fbe",
    createdAt: "2023-11-02T14:13:53.260873Z",
    updatedAt: ""
  },
]