import { useEffect, useState } from "react";
import { Media, mediaDefault } from "../types/media";
import apiGetMedia from "../api/media/apiGetMedia";
import apiAddMedia from "../api/media/apiAddMedia";
import { AddMediaReqForm } from "../types/mediaContract";
import { useLoadingContext } from "../contexts/LoadingContext";
import { ErrorI } from "../types/api";

const useMedia = (id?: string) => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(mediaDefault);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  const get = async () => {
    if (!id) throw "id is undefined";

    let resData: Media | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiGetMedia(id);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  const add = async (data: AddMediaReqForm) => {
    let resData: Media | undefined = undefined;
    let error: ErrorI | undefined = undefined;

    try {
      setIsLoading(true);

      const res = await apiAddMedia(data);

      setData(res.json.data);
      resData = res.json.data;
    } catch (err) {
      // TODO!: error handler
      console.error(err);
      if ("message" in (err as ErrorI)) {
        error = err as ErrorI;
      }
    } finally {
      setIsLoading(false);
    }

    return { resData, error };
  };

  return {
    data,
    isLoading,
    get,
    add,
  };
};

export default useMedia;
