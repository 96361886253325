import React, { useEffect, useState } from 'react';
import ModalTaskSubmit from '../../../components/admin/ModalTaskSubmit';

import useUserList from '../../../hooks/useUserList';
import { useAppContext } from '../../../contexts/DataContext';
import { UserRole } from '../../../types/user';
import { GetRouteRes } from '../../../types/routeContract';

interface collectionProps {
  isOpen: boolean
  routeData: GetRouteRes[]
  onClose: () => void
}

const Collections: React.FC<collectionProps> = ({ isOpen, routeData, onClose }) => {
  const {
    get: getDriver
  } = useUserList()
  
  const { state } = useAppContext()

  const [selectedDrivers, setSelectedDrivers] = useState<string[]>([]);

  const handleSelectDriver = (index: number, driverId: string) => {
    const updatedSelectedDrivers = [...selectedDrivers];
    updatedSelectedDrivers[index] = driverId;
    setSelectedDrivers(updatedSelectedDrivers);
  };

  useEffect(() => {
    (async () => {
      const janitorQuery = {
        role: UserRole.Janitor,
        limit: -1
      };
      if (state.drivers.length === 0) await getDriver(janitorQuery);
    })()
  }, [])

  useEffect(() => {
    if(!isOpen) {
      setSelectedDrivers([]);
    }
  }, [isOpen])

  if (!isOpen) return null

  return <>
    <div
      className="fixed z-[1000] inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
          onClick={onClose}
        ></div>

        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
          &#8203;
        </span>

        <div className='inline-block align-bottom bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full'>
          <div
            className="max-h-96 px-4 text-left overflow-y-auto"
            role="dialog"
            aria-labelledby="modal-title"
          >
            <div className='py-4 text-xl font-bold'>
              Pilih Driver
            </div>
            <div className='flex flex-col mb-4'>
              {routeData?.map((item, index) => (
                <div className='py-1'>
                  <ModalTaskSubmit
                    key={index}
                    item={item}
                    index={index}
                    driver={state.drivers}
                    selectedDrivers={selectedDrivers}
                    onSelectDriver={handleSelectDriver}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>

  </>
};

export default Collections;