import { Dump } from "../../types/dump";
import { GetDumpListReqQuery } from "../../types/dumpContract";
import Api from "../api";

export default async function apiGetDumpList(query?: GetDumpListReqQuery) {
  return await Api.get<Dump[]>({
    input: "/api/v1/dumps",
    query,
  });
}
