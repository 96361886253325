import { MapSector } from "../../types/mapSector";
import { AddMapSectorReq } from "../../types/mapSectorContract";
import Api from "../api";

export default async function apiAddMapSector(data: AddMapSectorReq) {
  const body = JSON.stringify(data);

  return await Api.post<MapSector>({
    input: "/api/v1/map-sector",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
