import { useEffect, useState } from "react"

interface DataItem {
    [key: string]: any
}

interface filterTerm {
    keys: string[]
}

const useFilter = (initialData: DataItem[], filterTerm: filterTerm) => {
    const [data, setData] = useState<DataItem[]>(initialData)
    const [term, setTerm] = useState<string>('')

    useEffect(() => {
        const filter = () => {
            const filterData = initialData.filter((item) => 
                filterTerm.keys.some((key) => 
                    (item[key] || "").toString().toLowerCase().includes(term.toLowerCase())
                )
            )
            setData(filterData)
        };
        filter()
    }, [term])

    const handleFilter = (term: string) => {
        setTerm(term)
    }

    return { handleFilter, data }
}

export default useFilter