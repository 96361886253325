import React, { useState, useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Navbar from './components/public/Navbar';
import About from './pages/pub/About';
import Contact from './pages/pub/Contact';
import Report from './pages/pub/Report';
import MCU from './pages/admin/mcu/MCU';
import TPSMap from './pages/pub/TPSMap';
import Signin from './pages/auth/Signin';
import Dashboard from './pages/Dashboard';
import Mapping from './pages/Map';
import AdmNavbar from './components/admin/Navbar';
import Dataview from './pages/admin/dataview/DataView';
import DataDump from './pages/admin/dataview/DataDump';
import DataTruck from './pages/admin/dataview/DataTruck';
import DataSector from './pages/admin/dataview/DataSector';
import DataReport from './pages/admin/dataview/DataReport';
import DataRoute from './pages/admin/dataview/DataRoute';
import DataCollections from './pages/admin/dataview/DataCollections';
import DataDriver from './pages/admin/dataview/DataDriver';
import useMapSectorList from './hooks/useMapSectorList';
import TransitionSlide from './components/TransitionSlide';
import Janitor from './pages/janitor/Janitor';
import { UserRole } from './types/user';
import Task from './pages/janitor/Task';
import RouteTask from './pages/janitor/Route';
import DataVolume from './pages/admin/dataview/DataVolume';
import useAuth from './hooks/useAuth';
import { useLoadingContext } from './contexts/LoadingContext';

const App: React.FC = () => {
  const { incrementLoading, decrementLoading } = useLoadingContext()
  const { data: auth,  getAuth } = useAuth();
  const [ isLoading, setIsLoading ] = useState(true);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { data: sectorListData, get: getSectorList } = useMapSectorList();

  //state options
  const [selectedOpt, setSelectedOpt] = useState("");

  const [options, setOptions] = useState<{ value: string, label: string }[]>([]);

  useEffect(() => {
    isLoading ? incrementLoading() : decrementLoading();
  }, [isLoading])

  useEffect(() => {
    (async () => {
      await getAuth();
      setIsLoading(false)
    })();

    getSectorList({limit: -1})
  }, []);

  useEffect(() => {
    // Update options based on the current route
    if (auth.token) {
      if (pathname === "/login") {
        navigate("/", { replace: true });
        return;
      }

      if (pathname !== '/') {
        setOptions([
          { value: 'data-dumps', label: 'Lokasi Pembuangan' },
          { value: 'data-sectors', label: 'Sector' },
          { value: 'data-trucks', label: 'Truk' },
          { value: 'data-janitor', label: 'Petugas Kebersihan' },
          { value: 'log-reports', label: 'Log Laporan' },
          { value: 'log-routes', label: 'Log Rute' },
          { value: 'log-collections', label: 'Log Pengambilan' },
          { value: 'log-garbages-volume', label: 'Log Volume Sampah' },
        ]);
      }
    }
  }, [auth, pathname])
  
  useEffect(() => {
    if(pathname !== "/") return;

    setOptions(sectorListData.map((sector) => ({
      value: sector.id,
      label: sector.name,
    })));
  }, [sectorListData, pathname])

  const navLinks = [
    { path: '/', label: 'Beranda', role: [''] },
    { path: '/peta', label: 'Peta', role: [''] },
    { path: '/contact', label: 'Kontak', role: [''] },
    { path: '/about', label: 'Tentang', role: [''] },
    { path: '/pelaporan', label: 'Pelaporan', role: [''] },

    { path: '/', label: 'Peta', role: ['admin'] },
    { path: '/dataview', label: 'DataView', role: ['admin'] },

    { path: '/', label: 'Dashboard', role: ['janitor'], },
    { path: '/task', label: 'Task', role: ['janitor'], },
    { path: '/route', label: 'Rute', role: ['janitor'], },
  ];

  const [searchTerm, setSearchTerm] = useState<string>("");

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="App min-h-screen flex flex-col">
      <ToastContainer />
      {auth.token ?
        <AdmNavbar
          links={navLinks.filter(link => link.role.includes(auth.role.toLowerCase()))}
          pathname={pathname}
          options={options}
          selectedOpt={selectedOpt}
          setSelectedOpt={(selected: string) => setSelectedOpt(selected)}
          searchTerm={searchTerm}
          setSearchTerm={(term: string) => setSearchTerm(term)}
          role={auth.role}
        />
        :
        <Navbar
          links={navLinks.filter(link => link.role.includes(auth.role.toLowerCase()))}
        />}
      {/* Use Routes instead of Switch */}
      <Routes>
        <Route path='login' element={<Signin />} />
        {auth.token ?
          (auth.role === UserRole.Admin ?
            <>
              <Route path="/" element={<Mapping selectedOpt={selectedOpt} searchTerm={searchTerm} />} />
              <Route path='/dataview' element={<Dataview />}>
                <Route path='' element={<DataDump searchTerm={searchTerm} />} />
                <Route path='data-dumps' element={<DataDump searchTerm={searchTerm} />} />
                <Route path='data-sectors' element={<DataSector searchTerm={searchTerm} />} />
                <Route path='data-trucks' element={<DataTruck searchTerm={searchTerm} />} />
                <Route path='data-janitor' element={<DataDriver searchTerm={searchTerm} />} />
                <Route path='log-reports' element={<DataReport searchTerm={searchTerm} />} />
                <Route path='log-routes' element={<DataRoute searchTerm={searchTerm} />} />
                <Route path='log-collections' element={<DataCollections searchTerm={searchTerm} />} />
                <Route path='log-garbages-volume' element={<DataVolume searchTerm={searchTerm} />} />
              </Route>
              <Route path="/mcu" element={<MCU />} />
            </>
            :
            <>
              <Route path="/" element={<Janitor />} />
              <Route path="/task" element={<Task />} />
              <Route path="/route" element={<RouteTask />} />
            </>)
          :
          <>
            <Route path="/" element={
              <TransitionSlide>
                <Dashboard />
              </TransitionSlide>
            } />
            <Route path='peta' element={
              <TransitionSlide>
                <TPSMap />
              </TransitionSlide>
            } />
            <Route path='contact' element={
              <TransitionSlide>
                <Contact />
              </TransitionSlide>
            } />
            <Route path='about' element={
              <TransitionSlide>
                <About />
              </TransitionSlide>
            } />
            <Route path='pelaporan' element={
              <TransitionSlide>
                <Report />
              </TransitionSlide>
            } />
          </>
        }
      </Routes>
    </div>
  );
};

export default App;