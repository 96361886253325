import { LogReport } from "../../types/logReport";
import { AddLogReportReq } from "../../types/logReportContract";
import Api from "../api";

export default async function apiAddLogReport(data: AddLogReportReq) {
  const body = JSON.stringify(data);

  return await Api.post<LogReport>({
    input: "/api/v1/log-report",
    init: {
      body,
      headers: {
        "content-type": "application/json",
      },
    },
  });
}
